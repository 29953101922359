import $, { css, get } from "jquery";
import "@fancyapps/fancybox";
import "magnific-popup/dist/jquery.magnific-popup.min";
// import "inputmask/dist/inputmask.min";
import 'slick-carousel';
import "./menu";
import "./faqSearch";
import "./supported-games";
import "./checkout";
// import 'inputmask';
// import "./jquery.steps.min";
import initializeMasonry from './functions/masonry';
import initializeBigSwiper from './functions/bigSlider';
import initializeOptionPopupSlider from './functions/optionPopupSlider';
import initializeheroSlider from './functions/heroSlider';
import initializeProductSlider from './functions/productSlider';
import 'select2';

// import smartWizard from 'smartwizard';
// import validate from 'jquery-validation';

$(function ($) {

	///PromoTracing
	const queryString = window.location.search;
	const urlParamsS = new URLSearchParams(queryString);
	
	if (urlParamsS.has('rpc'))  {
		localStorage.setItem("rpc", urlParamsS.get('rpc'));
	}
	if (urlParamsS.has('promo_code'))  {
		localStorage.setItem("promo_code", urlParamsS.get('promo_code'));
	}
	if (urlParamsS.has('utm_campaign'))  {
		localStorage.setItem("utm_campaign", urlParamsS.get('utm_campaign'));
    }
	if (urlParamsS.has('utm_medium'))  {
		localStorage.setItem("utm_medium", urlParamsS.get('utm_medium '));
    }
	if (urlParamsS.has('utm_content'))  {
		localStorage.setItem("utm_content", urlParamsS.get('utm_content'));
    }
	if (urlParamsS.has('source'))  {
		localStorage.setItem("source", urlParamsS.get('source'));
    }	
	if (urlParamsS.has('uuid'))  {
		localStorage.setItem("uuid", urlParamsS.get('uuid'));
	}
	if (urlParamsS.has('p'))  {
		localStorage.setItem("p", urlParamsS.get('p'));
	}
	
	
	$(".menu-icon").on('click',function(){
		if(!$(this).find(".cart-count").height()){			
			$.magnificPopup.open({
        		items: {
        			src: '#empty-popup'
        		},
        		mainClass: 'successful-popup',
			});
			return false;
		}
	});
		
  // With custom buttons
  var backButton = '<span class="slick-prev"></span>';
  var nextButton = '<div class=""><span class="slick-next"></span></div>'
  $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.swiper-slide-active .slider-nav',
    // customCount: function (slick) {
    //   $('.slider-counter').text((slick.currentSlide + 1) + ' / ' + slick.slideCount);
    // }
  });
  $('.slider-nav').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    asNavFor: '.swiper-slide-active .slider-for',
    dots: false,
    // fade: true,
    // arrows: true,
    // infinite: false,
    // autoplay: false,
    // centerMode: true,
    variableWidth: true,
    focusOnSelect: true,
    prevArrow: backButton,
    nextArrow: nextButton,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });
  
  //Show simulator tabs
  $('.product-header__tabs').animate({ 'opacity': '1' });
  $('.product-header__tabs').animate({ 'margin-bottom': '+=64px' }, 1200);
  $('.border-bg').animate({ 'margin-bottom': '+=8px' }, 1200);
  
  // Add click event to slider items to sync them
  $('.swiper-slide-active .slider-nav .slick-slide').on('click', function () {

    var slideIndex = $(this).data('slick-index');
    
    $('.slider-for').slick('slickGoTo', slideIndex);
  });
  // Add event handlers for updating slider count
  // Trigger the custom count function on page load
  $('.slider-for').on('init', function (event, slick) {
    $('.swiper-slide-active .slider-counter').text('1/' + slick.slideCount);
  });

  // Add event handler for updating slider count
  $('.slider-for').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    var currentSlideNumber = currentSlide + 1;
    var totalSlides = slick.slideCount;
    $('.swiper-slide-active .slider-counter').text(currentSlideNumber + '/' + totalSlides);
  });


  const wizard = $('#dof_wizard').smartWizard({
    transitionEffect: 'slide',
    progressbar: false,
    enableUrlHash: false,
    justified: false,
    toolbar: {
      position: 'none',
      showNextButton: false,
      showPreviousButton: false,
      extraHtml: ''
    },
    toolbarSettings: {
      toolbarPosition: 'none'
    },
    keyboard: {
      keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
      keyLeft: [37], // Left key code
      keyRight: [39] // Right key code
    },
  });

  function initializeHeaderBehavior() {
    const $header = $(".header");
    const $main = $(".site-content");
    const headerHeight = $header.outerHeight();
    // $main.css("top", headerHeight + "px");

    let lastScroll = 0;

    $(window).on('scroll', function () {
      if (!$header.hasClass("header-open-menu")) {
        let currentScroll = $(window).scrollTop();

        if (currentScroll > lastScroll) {
          // scrolled down
          $header.removeClass("header-scroll-up").addClass("header-scroll-down");
        } else {
          // scrolled up -- header show
          $header.removeClass("header-scroll-down").addClass("header-scroll-up");
        }

        lastScroll = currentScroll;
      }
    });

    $header.on('mouseover', function () {
      if (!$header.hasClass("header-open-menu")) {
        // Remove the class that hides the header when mouseover occurs
        $header.removeClass("header-scroll-down");
      }
    });
  }

  // Call the function to initialize header behavior
  initializeHeaderBehavior();

  function getCookie(name) {
    const cookieValue = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
  }
  

//set cookie
function setCookie(name, value, props) {

   props = props || {}

   var exp = props.expires

   if (typeof exp == "number" && exp) {

       var d = new Date()

       d.setTime(d.getTime() + exp*1000)

       exp = props.expires = d

   }

   if(exp && exp.toUTCString) { props.expires = exp.toUTCString() }

   value = encodeURIComponent(value)

   var updatedCookie = name + "=" + value

   for(var propName in props){

       updatedCookie += "; " + propName

       var propValue = props[propName]

       if(propValue !== true){ updatedCookie += "=" + propValue }
   }

   document.cookie = updatedCookie

}

  // Event handler for the "Previous" button click
  // $('.wizard-prev-btn').on('click', function () {
  $('body').on('click', '.wizard-prev-btn', function (e) {
    e.preventDefault();

    var stepPrev = $(this).attr("data-step-prev");
    // Check if the previous step is either step 2 or step 3
    if (stepPrev === '0') {
      const wizardType = getCookie('wizard_type');
      // Remove the "wizard_type" cookie
      document.cookie = "wizard_type=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      $('#step-7, #step-6, #step-5').removeClass(wizardType);
    }

    // Proceed to the previous step
    wizard.smartWizard("goToStep", stepPrev);
    // goToPreviousStep();
  });

  function goToStep(step) {
    wizard.smartWizard("goToStep", step);
  }

  $('.wizard-racing').on('click', function () {
    goToStep(1);
  });



  $('.choose-your-rig__row .choose-your-rig__col').on('click', function () {
    // Remove the existing cookie
    document.cookie = "wizard_type=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // Get the class of the clicked element
    var classes = $(this).attr('class').split(' ');
    // Find the class that starts with "wizard-"
    var wizardClass = classes.find(function (className) {
      return className.startsWith('wizard-');
    });
    // Extract the part after "wizard-" (e.g., "racing")
    var wizardType = wizardClass.replace('wizard-', '');
    // Write the extracted part to a cookie
    document.cookie = "wizard_type=" + wizardType + "; path=/";
    // For demonstration, logging the extracted wizard type
    // console.log('Wizard type:', wizardType);
    $('#step-7').load(location.href + ' #step-7 .container').addClass(wizardType);
    $('#step-6').load(location.href + ' #step-6 .container').addClass(wizardType);
    $('#step-5').load(location.href + ' #step-5 .container').addClass(wizardType);
    // goToStep(1);
  });



  $('.wizard__step-3').on('click', function (e) {
    e.preventDefault();
    const prevBtn = $("#step-3").find('.wizard-prev-btn');
    prevBtn.attr('data-step-prev', '0');
    goToStep(2);
  });

  $('.wizard-all').on('click', function (e) {
    e.preventDefault();
    const prevBtn = $("#step-3").find('.wizard-prev-btn');
    prevBtn.attr('data-step-prev', '1');
    goToStep(2);
  });

  $('.wizard-myself').on('click', function (e) {
    e.preventDefault();
    const prevBtn = $("#step-4").find('.wizard-prev-btn');
    prevBtn.attr('data-step-prev', '2');
    goToStep(3);
  });

  $('.wizard-cockpit').on('click', function (e) {
    e.preventDefault();
    const prevBtn = $("#step-5").find('.wizard-prev-btn');
    prevBtn.attr('data-step-prev', '3');
    goToStep(4);
  });

  $('.wizard-not_cockpit').on('click', function (e) {
    e.preventDefault();
    const prevBtn = $("#step-6").find('.wizard-prev-btn');
    prevBtn.attr('data-step-prev', '3');
    goToStep(5);
  });

  $('.wizard-business').on('click', function (e) {
    e.preventDefault();
    const prevBtn = $("#step-7").find('.wizard-prev-btn');
    prevBtn.attr('data-step-prev', '2');
    goToStep(6);
  });


  const urlParams = new URLSearchParams(window.location.search);
  const desiredStep = urlParams.get('step');
  if (desiredStep) {
    $('#smartwizard').smartWizard('goToStep', parseInt(desiredStep));
  }




  initializeMasonry();
  initializeBigSwiper();
  initializeheroSlider();
  initializeProductSlider();
  // initializeOptionGalleryProductSlider();
  initializeMagnificPopup();
  initializeMagnificPopupIMG();
  initializeOptionPopupSlider();
  initializeAccordion();
  loadMorePosts();
  loadMoreReviewsByTerm();
  loadMoreNews();
  adjustIndent();

  const activateTab = (tabNumber) => {
    $('.tab').removeClass('active');
    $('.tab-content').removeClass('active');

    const tab = $(`.tab[data-tab="${tabNumber}"]`);
    const tabContent = $(`.tab-content[data-tab="${tabNumber}"]`);

    tab.addClass('active');
    tabContent.addClass('active');
  };

  $('.motion-simulators-menu .tab').on('click', function () {
    const tabNumber = $(this).data('tab');
    activateTab(tabNumber);
  });

  // Add an event listener for the block with class flight-simulators
  $('.flight-simulators').on('click', function () {
    // Assuming you want to open tab 2 (change 2 to the desired tab number)
    const tabNumber = 2;
    activateTab(tabNumber);
  });

  // Add an event listener for the block with class racing-simulators
  $('.racing-simulators').on('click', function () {
    // Open tab 1 when clicking on racing-simulators
    const tabNumber = 1;
    activateTab(tabNumber);
  });

  // Initialize Magnific Popup for YouTube and Vimeo videos
  function initializeMagnificPopup() {
    $('.popup-youtube, .popup-vimeo').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 300,
      preloader: true,
      mainClass: 'video-class',
      fixedContentPos: true,
      iframe: {
        patterns: {
          youtube: {
            index: 'youtube.com',
            id: 'v=',
            src: 'https://www.youtube.com/embed/%id%?rel=0&autoplay=1'
          },
          vimeo: {
            index: 'vimeo.com/',
            id: function (url) {
              var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
              if (!m || !m[5]) return null;
              return m[5];
            },
            src: 'https://player.vimeo.com/video/%id%?autoplay=1'
          }
        }
      },
      callbacks: {
        open: function () {
          // Add a class to the body element when the popup is opened
          $('body').addClass('popup-open');
        },
        close: function () {
          // Remove the class from the body element when the popup is closed
          $('body').removeClass('popup-open');
        }
      }
    });       
  }
  

  // Initialize Magnific Popup for IMG
  function initializeMagnificPopupIMG() {
    $('.popup-img').magnificPopup({
      type: 'image',      
      removalDelay: 300,
      preloader: true,      
      mainClass: 'video-class',         
      callbacks: {
        open: function () {
          // Add a class to the body element when the popup is opened
          $('body').addClass('popup-open');
        },
        close: function () {
          // Remove the class from the body element when the popup is closed
          $('body').removeClass('popup-open');
        }
      }
    }); 
    
    $('.reviews-grid .item .content_toggle').each(function(){
    	$(this).unbind('click').bind('click',function(event){        		
    		event.preventDefault();   // блокировать переход по ссылке
    		const $link= $(this); // a - конкретная ссылка которая была нажата
    		const $content = $link.prev('div.text__p');  // div -контент блок перед ссылкой    		
    		$content.toggleClass('hide'); // показать или скрыть контент блок	
    		const htmlLink  = $content.hasClass('hide') ? 'Show more' :  'Hide'; 
    		$link.html(htmlLink);	
    		initializeMasonry();
  	  	});
    });
  }
  
//Function to show the preloader
  function showPreloader() {
      // Display your preloader element here
      // For example:
  	jQuery.blockUI({overlayCSS: {backgroundColor: '#fff',opacity:0.4},message:'' });
  }

  // Function to hide the preloader
  function hidePreloader() {
      // Hide your preloader element here
      // For example:
  	jQuery.unblockUI();
  }


  let selectedTaxonomy1 = '';
  let selectedTaxonomy2 = '';

  // Find the item with the 'active' class on page load for taxonomy 1
  var $activeItem1 = $('#taxonomy1-dropdown ul li.active');


  // Check if there is an active item for taxonomy 1
  if ($activeItem1.length > 0) {
    selectedTaxonomy1 = $activeItem1.attr('tax-review-id');
  }


  $('#taxonomy1-dropdown ul li').on('click', function () {
    // Remove the 'active' class from all items and add it to the clicked item
    $('#taxonomy1-dropdown ul li').removeClass('active');
    $(this).addClass('active');

    selectedTaxonomy1 = $(this).attr('tax-review-id');
    filterPosts(selectedTaxonomy1, selectedTaxonomy2);
  });

  $('#taxonomy2-dropdown ul li').on('click', function () {
    // Remove the 'active' class from all items and add it to the clicked item
    $('#taxonomy2-dropdown ul li').removeClass('active');
    $(this).addClass('active');

    selectedTaxonomy2 = $(this).attr('tax-by-type-id');
    filterPosts(selectedTaxonomy1, selectedTaxonomy2);
  });

  function filterPosts(taxonomy1, taxonomy2) {
    // Cache the elements that are frequently used to avoid redundant DOM traversal.
    // const $activeCategory = $('.category-filter ul li.active');
    const $reviews = $('.reviews');
    const $reviewsGrid = $('.reviews-grid');
    $reviewsGrid.parent().addClass('ajax-loading');
    // $(this).addClass('active');
    const taxReviewId = taxonomy1;
    const taxByTypeId = taxonomy2;

    // Prepare the data object for the AJAX request.
    const data = {
      action: "reviews_ajax",
      tax_review_id: taxReviewId,
      tax_by_type_id: taxByTypeId,
    };
    // Perform the AJAX request.
    $.ajax({
      type: "POST",
      url: dofreality_ajax.url,
      data: data,
      beforeSend: function () {
        // Show a loading indicator or perform other pre-request actions if needed.
      },
    }).done(function (response) {
      // Update the content with the AJAX response.
      $reviewsGrid.empty();
      $reviewsGrid.append(response);

      // Reload Masonry after new content is added
      initializeMasonry();

      // Reinitialize Magnific Popup after new content is added
      initializeMagnificPopup();      
    }).fail(function (response) {
      // Handle AJAX request failures, e.g., display an error message.
      console.log(response);
    }).always(function () {
      // Remove the 'reviews-ajax' class after the AJAX request is completed.
      $reviews.removeClass('reviews-ajax');
    });
  }



  $('body').on('click', '.category-filter-inner ul li', function (e) {
    e.preventDefault();
    $('.category-filter-inner ul li.active').removeClass('active');
    $('.reviews').addClass('reviews-ajax');
    $(this).addClass('active');

    const catReviewId = $(this).attr("cat-reviews-id");
    const taxTermInnerId = $(this).attr("tax-term-inner-id");
    const data = {
      action: "reviews_ajax_inner",
      car_review_id: catReviewId,
      tax_term_inner_id: taxTermInnerId,
    };
    $.ajax({
      type: "POST",
      url: dofreality_ajax.url,
      data: data,
      beforeSend: function () {
      },
    }).done(function (response) {
      $('.reviews-grid').empty();
      $('.reviews-grid').append(response);
      // Reload Masonry after new content is added
      initializeMasonry();
      // Reinitialize Magnific Popup after new content is added
      initializeMagnificPopup();
    }).fail(function (response) {
      // console.log(response);
    });
  });

  $('body').on('click', '.load-more-button', function (event) {
    event.preventDefault();
    let _this = $(this);
    _this.html('Loading...'); // changing the button 
    let data = {
      'action': 'reviews_loadmore',
      'query': _this.attr('data-param-posts'),
      'tax_id': _this.attr('data-tax-id'),
      'page': this_page,
    }
    $.ajax({
      url: reviews_loadmore_params.ajaxurl,
      data: data,
      type: 'POST',
      success: function (data) {
        if (data) {
          _this.html('Load More');
          _this.prev().before(data);
          // _this.prev().prev().append(data); // where to insert the data 
          this_page++;
          initializeMagnificPopup();
          initializeMasonry();          
          if (this_page == _this.attr('data-max-pages')) {
            _this.remove();
          }
        } else {
          _this.remove();
        }
      }
    });
  });

  function loadMorePosts() {
    let page = 1;
    let loading = false;
    let container = $('.reviews-grid');
    let loadMoreButton = $('#load-more');

    loadMoreButton.on('click', function () {
      let maxPages = $(this).attr('max-pages');
      if (!loading) {
        loading = true;
        loadMoreButton.text('Loading...');

        $.ajax({
          url: ajaxurl,
          type: 'post',
          data: {
            action: 'load_more_posts',
            page: page,
          },
          success: function (response) {
            if (response) {
              container.append(response);
              page++;
              loading = false;
              loadMoreButton.text('Load More');

              initializeMagnificPopup();
              initializeMasonry();
              initializeMagnificPopupIMG();
              if (page == maxPages) {
                loadMoreButton.remove();
              }
            } else {
              loadMoreButton.remove();
            }
          },
        });
      }
    });
  }

  function loadMoreReviewsByTerm() {
    let page = 1;
    let loading = false;
    let container = $('.reviews-grid');
    let loadMoreButton = $('#load-more-reviews');

    loadMoreButton.on('click', function () {
      let termId = $(this).attr('term_id');
      let maxPages = $(this).attr('max-pages');
      if (!loading) {
        loading = true;
        loadMoreButton.text('Loading...');

        $.ajax({
          url: ajaxurl,
          type: 'post',
          data: {
            action: 'load_more_review_by_term',
            term_id: termId,
            page: page,
          },
          success: function (response) {
            // console.log(page++);
            if (response) {
              container.append(response);
              page++;
              loading = false;
              loadMoreButton.text('Load More');

              initializeMagnificPopup();
              initializeMasonry();
              initializeMagnificPopupIMG();
              if (page == maxPages) {
                loadMoreButton.remove();
              }
            } else {
              loadMoreButton.remove();
            }
          },
        });
      }
    });
  }



  /**
   * Initializes the accordion functionality.
   * This function hides all accordion contents, opens the specified accordion item from the hash fragment,
   * or opens the first accordion item by default if no hash is present.
   * It also handles click events on accordion items to toggle their visibility and scroll to the opened item.
   */
  function initializeAccordion() {
    // Hide all accordion contents initially
    $('.accordion .accordion-list > li > .accordion__content').hide();

    // Open the accordion item specified in the hash fragment
    const hash = window.location.hash;
    if (hash) {
      const accordionItemToOpen = $(hash);
      if (accordionItemToOpen.length) {
        accordionItemToOpen.addClass('active').find('.accordion__content').slideDown();
        // Scroll to the top of the opened accordion item
        $('html, body').animate({
          scrollTop: accordionItemToOpen.offset().top - 20
        }, 500); // Adjust the scroll speed as needed
      }
    } else {
      // Open the first accordion item by default if no hash is present
      $('.accordion .accordion-list > li').first().addClass('active').find('.accordion__content').slideDown();
    }

    $('.accordion .accordion__title').on('click', function () {
      const parentListItem = $(this).closest('li');
      if (parentListItem.hasClass('active')) {
        parentListItem.removeClass('active').find('.accordion__content').slideUp();
      } else {
        $('.accordion-list > li.active .accordion__content').slideUp();
        $('.accordion-list > li.active').removeClass('active');
        parentListItem.addClass('active').find('.accordion__content').slideDown();
      }
      return false;
    });
  }
  /**
   * Function to handle the "Load More" button click and load additional news items using AJAX.
   * This function listens for clicks on the "Load More" button with the class "load-more-news"
   * and sends an AJAX request to retrieve more news items from the server.
   * The new items are appended to the container with the class "our-news__row".
   * The page parameter keeps track of the current page number for pagination.
   */

  function loadMoreNews() {
    let page = 1;
    $('.load-more-news').on('click', function (e) {
      e.preventDefault();
      var nextPage = page + 1;

      $.ajax({
        url: ajaxurl,
        type: 'post',
        data: {
          action: 'load_more_news',
          page: nextPage,
        },
        success: function (response) {
          if (response.success) {
            var $newItems = $(response.data.html);
            $('.our-news__row').append($newItems);
            $('.load-more-news').attr('disabled', false);
            page++; // Increment the page for the next "Load More" click

            if (!response.data.hasMorePosts) {
              $('.load-more-wrap').remove(); // Hide the "Load More" button if no more posts
            }
          }
        },
        error: function (xhr, status, error) {
          console.log(xhr.responseText);
        },
      });
    });
  }

  $('.single_add_to_cart_button').on('click', function (e) {
    e.preventDefault(); // Prevent the default form submission

    const $button = $(this);
    const productId = $button.data('product-id');
    const currentProductId = $('.site-content').find('.product').data('current-product-id');
    const productType = $button.data('product-type');
    const $messageContainer = $('.single-upgrades__add-cart').find('.add-to-cart-message');
    // const option = $('.option-' + productId);
    const option = $('.option-list__row');    
    if($(this).parent().hasClass('remove-product')){    	    
    	$.ajax({
            url: wc_add_to_cart_params.ajax_url,
            type: 'POST',
            data: {
                action: 'remove_from_cart',
                product_id: productId              
            },
            success: function (response) {        	  
          	  $button.find(".option__text").text('Add to Cart');
  	          $button.parent().removeClass('remove-product');
  	          $('.header-item-right').load(location.href + ' .header-item-right');
  	          $('.sliding-bar__btn').load(location.href + ' .sliding-bar__btn');
  	          addPopupProduct();  	          
            }
        	}); 
    	return false;
    }else{
    	 showPreloader();
    $.ajax({
      type: 'POST',
      url: wc_add_to_cart_params.ajax_url,
      data: {
        action: 'dof_add_to_cart',
        product_id: productId,
        current_product_id: currentProductId,
        product_type: productType,
      },
      success: function (response) {
        // Check if the cart was successfully emptied before adding the new product
        // if (productType === 'simulator' && response.success) {
        //     // Optional: Show a message to the user that the cart was cleared
        //     alert('Cart cleared.');
        // }
    	  hidePreloader();

        // Update the cart count in the header
        if (response.success) {
          reloadRecycleBin($('.add-to-cart__item_inner_opt'));
          if (response.data.count_options) {
            $('.sliding-bar .sliding-bar__container .options-numbe__wrap').show();
            $('.sliding-bar .sliding-bar__container .options-numbe__wrap').find('.options_number').text('+' + response.data.count_options);
            $('.block-button.options-popup.sliding-bar-popup').hide();
          } else {
            $('.block-button.options-popup.sliding-bar-popup').show();
            $('.sliding-bar .sliding-bar__container .options-numbe__wrap').hide();
          }
          if (response.data.simulator_in_cart == false) {
            const pagePrice = parseFloat($('.page-price').find('span').text().replace(response.data.currency_symbol, '').trim());
            const totalPrice = parseFloat(response.data.price) + pagePrice;
            $('.sliding-bar .sliding-bar__container').find('.sliding-bar__price').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+response.data.currency_symbol+'</span>' + Math.round(totalPrice) + '</bdi></span>');
          } else {
            $('.sliding-bar .sliding-bar__container').find('.sliding-bar__price').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+response.data.currency_symbol+'</span>' + Math.round(response.data.price) + '</bdi></span>');
          }


          $messageContainer.html('<p>' + response.data.message + '</p>');
          $messageContainer.fadeIn().delay(2000).fadeOut();
          if(response.data.isRemove==1)$(".add-product-popup__note_option").show();

          // Load the updated content of the header-item-right block
          $('.header-item-right').load(location.href + ' .header-item-right');
          $('.sliding-bar__btn').load(location.href + ' .sliding-bar__btn');
          
    	  $button.find(".option__text").text('Remove from Cart');
	      $button.parent().addClass('remove-product');
	      $("#open-add-to-cart-popup").unbind('click');
	      window.dataLayer = window.dataLayer || [];
	      window.dataLayer = addGoogleEvent(window.dataLayer,productId,response.data.simulator_name,response.data.simulator_price);	      
       } else {

          alert('Failed to add product to cart.');
        }
      },
    });
    }
  });



  $('.desc-popup').magnificPopup({
    delegate: 'a',
    removalDelay: 500, //delay removal by X to allow out-animation
    fixedContentPos: true,
    mainClass: 'myClassOne',
    callbacks: {
      beforeOpen: function () {
        this.st.mainClass = this.st.el.attr('data-effect');
      }
    },
    midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source. // as we know that popup content is tall we set scroll overflow by default to avoid jump
  });

  $('.comparative-popup_open').magnificPopup({
    delegate: 'a',
    removalDelay: 500, //delay removal by X to allow out-animation
    fixedContentPos: true,
    mainClass: '',
    closeOnBgClick: false,
    callbacks: {
      beforeOpen: function () {
        this.st.mainClass = this.st.el.attr('data-effect');
      },
      // Callback function triggered before popup is closed
      beforeClose: function () {
        $('body').removeClass('comparative-popup__open');
      },
      open: function () {
        // Add a class to the body when the popup is opened
        $('body').addClass('comparative-popup__open');        	
      },
    },
    midClick: true,
  });

  function addPopupProduct(){
	  $('#open-add-to-cart-popup').on('click', function () {	  
	  const currentProductId = $(this).data('product-id');
	    $.ajax({
	      url: wc_add_to_cart_params.ajax_url,
	      type: 'POST',
	      data: {
	        action: 'open_add_to_cart_popup',
	        current_product_id: currentProductId,
	      },
	      success: function (response) {
	        if (response.success) {	          	        		        	
	          let product = response.data;
	          $('#add-product-popup .add-product-popup__bottom .add-product-popup__option a').text('Add Options for ' + product.shipping_attribute);	          
	        } else {
	          console.log('Error: ' + response.data);
	        }	      
  
	        $.magnificPopup.open({
	        	items: {
	        		src: '#add-product-popup', // Replace 'your-popup-id' with the ID of your popup content
	        		type: 'inline'
	        	},
	        	removalDelay: 500, // Delay removal by X to allow out-animation
	        	fixedContentPos: true,
	        	mainClass: 'add-product-popup__wrap',
	        	closeOnBgClick: false,
	        	midClick: true,
	        	callbacks: {
	        		beforeOpen: function () {
	        			this.st.mainClass = 'mfp-zoom-in add-product-popup__wrap'; // Add your desired animation class
	        		},
	        		beforeClose: function () {
	        			this.st.mainClass = 'mfp-zoom-out'; // Add your desired animation class
	        		}
	        	}
	        });
	      },
	    });
  });
  }
  
  $('.add-product-popup__option a').on('click', function (event) {
    // Prevent the default anchor behavior
    event.preventDefault();

    // Close the Magnific Popup
    $.magnificPopup.close();

    // Scroll to the target block with animation
    $('html, body').animate({
      scrollTop: $('#product__options').offset().top - 32// Scroll to the top position of the target block
    }, 1000); // Adjust the animation speed as needed (in milliseconds)
  });

  $(window).on('resize', function () {
    adjustIndent();
  });

  function adjustIndent() {
    const windowWidth = $(window).width();

    // Check if the .block-first .container element exists
    const marginBlock = $('.block-first .container').offset();
    if (!marginBlock) {
      return;
    }

    const marginBlockRight = windowWidth - (marginBlock.left + 1434);
    const calculatedMargin = windowWidth > 993 ? Math.max(24, marginBlockRight) : 0;

    // Check if the .block-second__container element exists
    const blockSecondContainer = $('.block-second__container');
    if (!blockSecondContainer) {
      return;
    }

    blockSecondContainer.css('margin-left', calculatedMargin + 'px');
  }

  // Function to update border background based on active tab
  function updateBorderBg() {
    var activeLiClasses = $(".product-header__tabs li.active").attr('class');
    $(".border-bg").removeClass().addClass("border-bg " + activeLiClasses);
  }

  // Initial call to update border background based on the active tab
  updateBorderBg();

  // Mouse enter event for tab items
  // $(".product-header__tabs li").mouseenter(function () {
  //   var liClass = $(this).attr('class'); // Get the class of the hovered li
  //   $(".border-bg").removeClass().addClass("border-bg " + liClass); // Update class of .border-bg
  // });

  // Mouse leave event for tab items
  $(".product-header__tabs li").mouseleave(function () {
    // Call the function to update border background based on the active tab
    updateBorderBg();
  });

  $('.step').hide();
  $('#step-contact').show();

  $('.next-step').click(function (event) {
    event.preventDefault(); // Prevent form submission

    const currentStep = $(this).closest('.step');
    const nextStep = currentStep.next('.step');
    const requiredFields = currentStep.find('[required]');
    let allFieldsFilled = true;

    // Validate required fields before proceeding
    requiredFields.each(function () {
      if ($(this).val() === '') {
        allFieldsFilled = false;
        $(this).addClass('error');
      } else {
        $(this).removeClass('error');
      }
    });

    if (allFieldsFilled) {
      // Update the order progress bar
      $('.progress-step').removeClass('active');
      $('.progress-step').eq(nextStep.index()).addClass('active');

      currentStep.hide();
      nextStep.show();
    }
  });

  $('.prev-step').click(function () {
    const currentStep = $(this).closest('.step');
    const prevStep = currentStep.prev('.step');

    currentStep.hide();
    prevStep.show();
  });


  const carouselSwiper = new Swiper('.carousel__swiper__container', {

    spaceBetween: 32,
    loop: true,
    // loopedSlides: 2,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      1024: {
        slidesPerView: 5,
        spaceBetween: 32,
      },
      915: {
        slidesPerView: 4,
      },
      600: {
        slidesPerView: 3,
      },
      375: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 15,
      }
    },
    navigation: {
      nextEl: '.swiper-button-next.carousel-next',
      prevEl: '.swiper-button-prev.carousel-prev',
      clickable: true,
    },
  });


  const $scrollLockedBlock = $('.sliding-bar');
  const $productContainer = $('.product__info');
  const $pagePrice = $('.page-price');

  // Function to update the right indentation and scroll-locked behavior
  // Check if $productContainer and $pagePrice exist before proceeding.
  if ($productContainer.length > 0 && $pagePrice.length > 0) {
    // Define a function to update styles based on scroll position and window width.
    function updateStyles() {
      const scrollTop = $(window).scrollTop();
      const windowWidth = $(window).width();
      let containerWidth = $productContainer.outerWidth();
      const containerLeftOffset = $productContainer.offset().left;

      if (windowWidth >= 993) {
        containerWidth += 80; // Add 80px when the width is greater than or equal to 993px
      }

      const rightValue = (windowWidth >= 993) ? (windowWidth - containerLeftOffset - containerWidth) + 'px' : 'auto';

      const pagePriceOffset = $pagePrice.offset().top;

      // Check if the top edge of .page-price is above the top edge of the viewport
      if (pagePriceOffset < scrollTop) {
        $scrollLockedBlock.css({
          position: 'fixed',
          bottom: 0,
          opacity: 1,
          right: rightValue,
        });
      } else {
        $scrollLockedBlock.css({
          position: 'absolute',
          bottom: 0,
          opacity: 0,
          right: rightValue,
        });
      }
    }

    // Call the updateStyles function initially and whenever the window is scrolled or resized.
    updateStyles();
    $(window).on('scroll resize', updateStyles);
  }

  // Initialize Select2 for country dropdowns
  $('select#billing_country').select2();
  $('select#billing_state').select2();


  const countryCodes = {
    'US': '+1',
    'AR': '+54',
    'AM': '+374',
    'AU': '+61',
    'AT': '+43',
    'BE': '+32',
    'BR': '+55',
    'BG': '+359',
    'CA': '+1',
    'CL': '+56',
    'CN': '+86',
    'HR': '+385',
    'CY': '+357',
    'CZ': '+420',
    'DK': '+45',
    'EG': '+20',
    'EE': '+372',
    'FI': '+358',
    'FR': '+33',
    'DE': '+49',
    'GR': '+30',
    'HK': '+852',
    'HU': '+36',
    'IS': '+354',
    'IN': '+91',
    'ID': '+62',
    'IE': '+353',
    'IL': '+972',
    'IT': '+39',
    'JP': '+81',
    'JO': '+962',
    'KE': '+254',
    'KW': '+965',
    'LV': '+371',
    'LT': '+370',
    'LU': '+352',
    'MY': '+60',
    'MX': '+52',
    'NL': '+31',
    'NZ': '+64',
    'NO': '+47',
    'PK': '+92',
    'PE': '+51',
    'PH': '+63',
    'PL': '+48',
    'PT': '+351',
    'QA': '+974',
    'RO': '+40',
    'RU': '+7',
    'SA': '+966',
    'SG': '+65',
    'SK': '+421',
    'SI': '+386',
    'ZA': '+27',
    'KR': '+82',
    'ES': '+34',
    'SE': '+46',
    'CH': '+41',
    'TW': '+886',
    'TH': '+66',
    'TR': '+90',
    'AE': '+971',
    'GB': '+44',
    'XX': '+XX' // Add a default country code for unrecognized countries
  };
  const masks = {
    'US': "+99999999999",
    'AR': "+99999999999",
    'AM': "+99999999999",
    'AU': "+99999999999",
    'AT': "+99999999999",
    'BE': "+99999999999",
    'BR': "+9999999999999",
    'BG': "+99999999999",
    'CA': "+99999999999",
    'CL': "+99999999999",
    'CN': "+9999999999999",
    'HR': "+999999999999",
    'CY': "+99999999999",
    'CZ': "+999999999999",
    'DK': "+9999999999",
    'EG': "+999999999999",
    'EE': "+99999999999",
    'FI': "+999999999999",
    'FR': "+99999999999",
    'DE': "+9999999999999",
    'GR': "+999999999999",
    'HK': "+99999999999",
    'HU': "+99999999999",
    'IS': "+9999999999",
    'IN': "+999999999999",
    'ID': "+9999999999999",
    'IE': "+99999999999",
    'IL': "+999999999999",
    'IT': "+9999999999999",
    'JP': "+999999999999",
    'JO': "+999999999999",
    'KE': "+999999999999",
    'KW': "+99999999999",
    'LV': "+99999999999",
    'LT': "+99999999999",
    'LU': "+99999999999",
    'MY': "+999999999999",
    'MX': "+99999999999999",
    'NL': "+99999999999",
    'NZ': "+99999999999",
    'NO': "+9999999999",
    'PK': "+999999999999",
    'PE': "+99999999999",
    'PH': "+999999999999",
    'PL': "+99999999999",
    'PT': "+999999999999",
    'QA': "+99999999999",
    'RO': "+99999999999",
    'RU': "+999999999999",
    'SA': "+999999999999",
    'SG': "+9999999999",
    'SK': "+999999999999",
    'SI': "+99999999999",
    'ZA': "+999999999999",
    'KR': "+9999999999999",
    'ES': "+99999999999",
    'SE': "+99999999999",
    'CH': "+99999999999",
    'TW': "+9999999999999",
    'TH': "+999999999999",
    'TR': "+999999999999",
    'AE': "+999999999999",
    'GB': "+999999999999",
    'XX': "+99999999999" // Default mask for unrecognized countries
  };

  // Function to initialize the input mask based on the selected country
  function initializeInputMask(selectedCountry) {	  
    if (selectedCountry == "XX") {
      // Disable the mask and use a placeholder for "XX"
      if(localStorage.getItem("billing_phone") && localStorage.getItem('billing_country')==selectedCountry){
    	  $('#billing_phone').val(localStorage.getItem('billing_phone'));
      	  $('#billing_phone').mask("99999999999", { placeholder: localStorage.getItem('billing_phone') });
      }else
    	  $('#billing_phone').mask("99999999999", { placeholder: "Phone ___________" });
    } else if (masks[selectedCountry]) {
      // Apply the specific mask for the selected country
      if(localStorage.getItem("billing_phone") && localStorage.getItem('billing_country')==selectedCountry){    	      	
    	  $('#billing_phone').val(localStorage.getItem('billing_phone'));
      	  $('#billing_phone').mask(masks[selectedCountry], { placeholder: localStorage.getItem('billing_phone') });
      }else{
    	  $('#billing_phone').mask(masks[selectedCountry], { placeholder: " " });
      }
    } else {
      // Set a default mask if the country is not defined
      $('#billing_phone').mask("+99999999999", { placeholder: " " });
    }   
  }

  function isInEurope(countryCode) {
    // List of European country codes
    const europeanCountryCodes = ["AT", "BE", "BG", "HR", "CY", "CZ", "DE", "DK", "EE", "FI", "FR", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
    return europeanCountryCodes.includes(countryCode);
  }

  function populateStatesDropdown(states) {
    var stateDropdown = $('#billing_state');
    stateDropdown.empty(); // Clear existing options
    $('#billing_state').select2();
    $.each(states, function (key, value) {
      stateDropdown.append($('<option></option>').attr('value', key).text(value));
    });
  }

  function getSelectedBillingState() {
    let selectedStateReturn = $('select#billing_state').val();
    return selectedStateReturn;
  }

  function addClassToStateSelect() {
    var selectedCountry = $('#billing_country').val();
    if (selectedCountry === 'US') {
      $('#billing_state').addClass('us-country');
    } else {
      $('#billing_state').removeClass('us-country');
    }
  }


  function handleBillingStateChange(selectedCountry) {
    // Check if the selected country is 'US'
    if (selectedCountry === 'US') {
      $(document).on('change', 'select.us-country', function () {
        let selectedState = getSelectedBillingState();
        $.ajax({
          type: 'POST',
          url: wc_checkout_params.ajax_url, // WordPress AJAX URL (automatically defined)
          data: {
            action: 'dof_payment_account',
            selected_country: selectedCountry,
            selected_state: selectedState,
          },
          success: function (response) {
            // Parse the JSON response
            const data = JSON.parse(response);

            // Initialize an empty array to hold the payment options
            const paymentOptions = [];
            /*console.log('===========================================');
            console.log('Country: ' + data.selected_country);
            console.log('State: ' + data.selected_state);
            console.log('US PayPal: ' + data.us_paypal);
            console.log('US Stripe: ' + data.us_stripe);
            console.log('Alternative NNA: ' + data.alt_nna);
            console.log('Bank account: ' + data.bank_account);
            console.log('Paypal account: ' + data.paypal_account);
            console.log('Stripe account: ' + data.stripe_account);
            console.log('Limit: ' + data.limit);
            console.log('===========================================');
             */
            // Remove the existing dof-payment-choose attribute            
            $('#step-2').removeAttr('dof-payment-choose');
            $('#payment_method_stripe').attr("data-account-code", data.stripe_account);
            $('#payment_method_paypal').attr("data-account-code", data.paypal_account);
            $('#payment_method_bacs').attr("data-account-code", data.bank_account);
            if (data.bank_account == '') {
              $('.payment_methods').find('.payment_method_bacs').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
            } else if (data.bank_account && data.paypal_account && data.stripe_account) {
              const showBacs = $('.payment_methods').find('.payment_method_bacs').show().removeClass("disabled-block").find('input').prop('disabled', false);
              if (showBacs) {
                $('.payment_box.payment_method_bacs').hide();
              }
            }

            $('.payment_methods').find('.wc_payment_method.payment_method_paypal').show().removeClass("disabled-block").find('input').prop('disabled', false);
            $('.payment_methods').find('.payment_method_stripe').show().removeClass("disabled-block").find('input').prop('disabled', false);
            if (data.bank_account) {
              paymentOptions.push(data.bank_account);
            }
            if (data.paypal_account) {
              paymentOptions.push(data.paypal_account);
            }
            if (data.stripe_account) {
              paymentOptions.push(data.stripe_account);
            }
            // Construct the attribute string without commas if there are no elements
            const dofPaymentChooseAttribute = paymentOptions.join(', ');

            // Set the attribute on the div
            $('#step-2').attr('dof-payment-choose', dofPaymentChooseAttribute);

          }
        });
      });
    }
  }

  function handleBillingWithoutStateChange(selectedCountry) {
    $.ajax({
      type: 'POST',
      url: wc_checkout_params.ajax_url, // WordPress AJAX URL (automatically defined)
      data: {
        action: 'dof_payment_account',
        selected_country: selectedCountry,
        selected_state: '',
      },
      success: function (response) {
        // Parse the JSON response
        const data = JSON.parse(response);
        // Initialize an empty array to hold the payment options
        const paymentOptions = [];
        /*console.log('====================================');
        console.log('Country: ' + data.selected_country);
        console.log('State: ' + data.selected_state);
        console.log('US PayPal: ' + data.us_paypal);
        console.log('US Stripe: ' + data.us_stripe);
        console.log('Alternative NNA: ' + data.alt_nna);
        console.log('Bank account: ' + data.bank_account);
        console.log('Paypal account: ' + data.paypal_account);
        console.log('Stripe account: ' + data.stripe_account);
        console.log('Limit: ' + data.limit);
        console.log('====================================');
		*/
        $('#payment_method_stripe').attr("data-account-code", data.stripe_account);
        $('#payment_method_paypal').attr("data-account-code", data.paypal_account);
        $('#payment_method_bacs').attr("data-account-code", data.bank_account);
       
        if (!data.bank_account) {
          $('.payment_methods').find('.wc_payment_method.payment_method_paypal').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
          $('.payment_methods').find('.payment_method_stripe').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
        } else {
          if (data.paypal_account && data.stripe_account) {
            $('.payment_methods').find('.wc_payment_method.payment_method_paypal').show().removeClass("disabled-block").find('input').prop('disabled', false);
            $('.payment_methods').find('.payment_method_stripe').show().removeClass("disabled-block").find('input').prop('disabled', false);
            const showBacs = $('.payment_methods').find('.payment_method_bacs').show().removeClass("disabled-block").find('input').prop('disabled', false);
            if (showBacs) {
              $('.payment_box.payment_method_bacs').hide();
            }
          } else if (!data.stripe_account && data.paypal_account) {
            $('.payment_methods').find('.payment_method_stripe').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
          } else if (!data.paypal_account && data.stripe_account) {
            $('.payment_methods').find('.wc_payment_method.payment_method_paypal').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
          } else if (!data.paypal_account && !data.stripe_account) {
            $('.payment_methods').find('.wc_payment_method.payment_method_paypal').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
            $('.payment_methods').find('.payment_method_stripe').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
            const showBacs = $('.payment_methods').find('.payment_method_bacs').show().removeClass("disabled-block").find('input').prop('disabled', false);
            if (showBacs) {
              $('.payment_box.payment_method_bacs').hide();
            }
          } else if (data.stripe_account && data.bank_account) {
            $('.payment_methods').find('.wc_payment_method.payment_method_paypal').hide().addClass("disabled-block").find('input').attr('disabled', 'disabled');
          }
        }
        // Remove the existing dof-payment-choose attribute
        $('#step-2').removeAttr('dof-payment-choose');
        if (data.bank_account) {
          paymentOptions.push(data.bank_account);
        }
        if (data.paypal_account) {
          paymentOptions.push(data.paypal_account);
        }
        if (data.stripe_account) {
          paymentOptions.push(data.stripe_account);
        }

        // Construct the attribute string without commas if there are no elements
        const dofPaymentChooseAttribute = paymentOptions.join(', ');

        // Set the attribute on the div
        $('#step-2').attr('dof-payment-choose', dofPaymentChooseAttribute);
      }
    });
  }
  const countriesWithoutStates = ['HR', 'CY', 'JO', 'LV', 'QA', 'LT', 'RU', 'SA', 'GB', 'TW', 'TH', 'AE', 'XX'];  
  // Listen for changes in the billing country dropdown using event delegation
  $(document).on('change', 'select#billing_country', function () {
    // Initial call	  
    addClassToStateSelect();    
    var promoCodeInput = $('.checkout-promo #promo_code');
    if (promoCodeInput.val() && localStorage.getItem('promo_code')==""){    
      promoCodeInput.val('');      
    }
    // Clear the promo_error element if it exists
    var promoErrorElement = $('#promo_error');
    if (promoErrorElement.length) {
      promoErrorElement.html(''); // Clear the content of the element
    }

    var selectedCountry = $(this).val();
    localStorage.setItem('country', selectedCountry);

    $.ajax({
      type: 'POST',
      url: wc_add_to_cart_params.ajax_url,
      data: {
        action: 'get_shipping_crm',
        selected_country: selectedCountry, // Ensure this matches the PHP variable name
      },
      success: function (response) {
        // Parse the JSON response
        var responseData = JSON.parse(response);
        var shippingData = responseData.dof_shipping;

        // Loop through each .dof-cart__product__inner element
        $('.dof-cart__products .dof-cart__product__inner').each(function () {
          var shippingAttribute = $(this).attr('shipping-attribute');
          var shippingPrice = 0;
          if(shippingData){
        	  // Check if the shipping attribute exists in the shipping data
        	  if (shippingData[selectedCountry] && shippingData[selectedCountry][shippingAttribute]) {
        		  // Retrieve the shipping price for the shipping attribute
        		  shippingPrice = shippingData[selectedCountry][shippingAttribute][0];
        	  }
          }
          // Update the shipping-product attribute with the new shipping price
          $(this).attr('shipping-product', shippingPrice);
        });
      },
    });

    var selectedAttributes = []; // Initialize an array to store selected product attributes
    var selectedProductsIDs = []; // Initialize an array to store selected product attributes

    // Iterate through all divs with the shipping-attribute attribute
    $('.dof-cart__products [shipping-attribute]').each(function () {
      var shippingAttribute = $(this).attr('shipping-attribute');
      selectedAttributes.push(shippingAttribute);
    });

    $('.dof-cart__products [data-product-id]').each(function () {
      var productId = $(this).attr('data-product-id');
      selectedProductsIDs.push(productId);

    });


    let billingStateField = $('#billing_state_field');
    // let billingStateUs = $('#custom_us_state_field');
    let phoneField = $('#billing_phone');

    // Clear the phoneField
    phoneField.val('');
    const proceedPaymentBtn = $('#step-2').find('.steps-buttons .proceed-payment-btn');
    const sendRequestBtn = $('#step-2').find('.steps-buttons .send-request-btn');
    if (selectedCountry === 'XX') {
    	$('.proceed-payment-btn').html('Place order'); 
    } else {
    	$('.proceed-payment-btn').html('Proceed to Payment');     	
    }

    if (countryCodes[selectedCountry]) {
      var countryCode = countryCodes[selectedCountry];
      if (countryCode != 'XX') {
        phoneField.val(countryCode);
      } else {
        phoneField.val()
      }
    }

    initializeInputMask(selectedCountry);

    let countryEurope = isInEurope(selectedCountry);

    if (selectedCountry === 'US') {
      handleBillingStateChange(selectedCountry);

    } else {
      handleBillingWithoutStateChange(selectedCountry);
    }

    if (countriesWithoutStates.indexOf(selectedCountry) === -1) {
      // Perform the AJAX call
      $.ajax({
        type: 'POST',
        url: wc_checkout_params.ajax_url,
        data: {
          action: 'get_states_for_country',
          selected_country: selectedCountry,
        },
        success: function (response) {
          const statesData = JSON.parse(response);

          if (statesData && statesData.states && Object.keys(statesData.states).length > 0) {
            // Check if the only state is "State/Province"
            if (Object.keys(statesData.states).length === 1 && statesData.states[''] === 'State/Province') {
              // Display text field if only "State/Province" is available
              $('.state_select').hide();
              // Check if the element is initialized as Select2 before destroying
              if ($('.state_select').data('select2')) {
                $('.state_select').select2('destroy');
              }
              $('#billing_state_field .input-text').remove(); // Remove any existing text input

              // Create a new text input with specified attributes
              const newTextInput = '<input type="text" id="billing_state" name="billing_state" placeholder="State/ Province" data-input-classes="" class="input-text billing-state-text">';
              $('#billing_state_field').append(newTextInput);

              // Add 'required' attribute to the text input
              $('#billing_state_field').removeClass('validate-required');

              $('#billing_state_field .input-text').on('input', function () {
                // Transfer data to the hidden field whenever the text input changes
                $('#hidden_billing_state').val($(this).val());
              });

              // handleBillingWithoutStateChange(selectedCountry);

            }
            else {
              // Display state dropdown if states are available
              $('.state_select').show();
              $('.state_select').select2().show();
              $('#billing_state_field .input-text').hide();
              // Remove 'required' attribute when hiding the text input
              $('#billing_state').removeAttr('required');
              populateStatesDropdown(statesData.states);
              // handleBillingStateChange(selectedCountry);
            }
          } else {
            // Handle the case when no states are available
            $('.state_select').hide();
            // Check if the element is initialized as Select2 before destroying
            if ($('.state_select').data('select2')) {
              $('.state_select').select2('destroy');
            }
            // Remove any existing text input
            $('#billing_state_field .input-text').remove();

            // Create a new text input with specified attributes
            const newTextInput = '<input type="text" id="billing_state" name="billing_state" placeholder="State/ Province" data-input-classes="" class="input-text">';
            $('#billing_state_field').append(newTextInput);

            // Add 'required' attribute to the text input
            $('#billing_state_field').removeClass('validate-required');

            $('#billing_state_field .input-text').on('input', function () {
              // Transfer data to the hidden field whenever the text input changes
              $('#hidden_billing_state').val($(this).val());
            });
            // handleBillingWithoutStateChange(selectedCountry);
          }
          if(localStorage.getItem("billing_state")){	    	    	
      		$("select#billing_state").val(localStorage.getItem('billing_state'));
      		$("select#billing_state").trigger("change");
          }	
        }
      });
    } else {
      // Handle the case when no states are available
      $('.state_select').hide();
      // Check if the element is initialized as Select2 before destroying
      if ($('.state_select').data('select2')) {
        $('.state_select').select2('destroy');
      }
      // Remove any existing text input
      $('#billing_state_field .input-text').remove();

      // Create a new text input with specified attributes
      const newTextInput = '<input type="text" id="billing_state" name="billing_state" placeholder="State/ Province" data-input-classes="" class="input-text billing-state-text">';
      $('#billing_state_field').append(newTextInput);

      // Add 'required' attribute to the text input
      $('#billing_state_field').removeClass('validate-required');

      $('#billing_state_field .input-text').on('input', function () {
        // Transfer data to the hidden field whenever the text input changes
        $('#hidden_billing_state').val($(this).val());
      });
    }
    // Make an AJAX request to calculate the shipping price
    $.ajax({
      type: 'POST',
      url: wc_checkout_params.ajax_url, // WordPress AJAX URL (automatically defined)
      data: {
        action: 'calculate_shipping_price',
        selected_country: selectedCountry,
        country_europe: countryEurope,
        selected_attributes: selectedAttributes.join(','), // Convert array to comma-separated string
        selected_products_ids: selectedProductsIDs.join(',') // Convert array to comma-separated string
      },
      success: function (response) {
        // Parse the JSON response
        const data = JSON.parse(response);
        const shippingCost = data.shipping_cost;
        const total = data.total;
        const tax = data.tax;
        const selected_country = data.selected_country;
        // var subtotal = data.subtotal; // Added to capture subtotal        
        if(data.shipping_cost_real==-2){                	
        	$('.delivery-price').html('Place order');
            $('.dof-tax').html('Place order');
        	$('.proceed-payment-btn').html('Place order');        	
        }else if (shippingCost == 0 && tax == 0 && selected_country != 'XX') {
          $('.delivery-price').html(data.currency_symbol+'0');
          $('.dof-tax').html(data.currency_symbol+'0');
        } else if (shippingCost == 0 && tax == 0) {
          $('.delivery-price').html('send request');
          $('.dof-tax').html('send request');
        } else {
          $('.delivery-price').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>' + shippingCost + '</bdi></span>');
          $('.cart-product__info-text .dof-tax').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>' + tax + '</bdi></span>');
        }

        $('.total').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>'+total+'</bdi></span>');
        // $('.subtotal .amount bdi').html(subtotal);
      }
    });

    $(document.body).on('checkout_order_processed', function () {
      // Get checkout data
      var customerName = $('input#billing_first_name').val() + ' ' + $('input#billing_last_name').val();
      var customerEmail = $('input#billing_email').val();

      // Populate Contact Form 7 fields
      $('input[name="your-name"]').val(customerName);
      $('input[name="your-email"]').val(customerEmail);
    });     
  });


  $('.styled-textarea').on('keyup', function () {
    let custom_data
    const nameField = confirmation_data.confirmation_name; // Replace with the desired name
    const emailField = confirmation_data.confirmation_email; // Replace with the desired email
    const orderIdField = confirmation_data.confirmation_order_id;
    // Assuming the data format in textarea is: Name \n Email \n Order ID
    $('input[name="confirmation-name"]').val(nameField);
    $('input[name="confirmation-email"]').val(emailField);
    $('input[name="confirmation-order-id"]').val(orderIdField);
  });

  // Hide the country code once the user starts entering their phone number
  $('#billing_phone').on('input', function () {
    let selectedCountry = $('select#billing_country').val();
    let countryCode = countryCodes[selectedCountry];
    let value = this.value;
    // Ensure the country code is added automatically as the user types
    if (countryCode && !value.startsWith(countryCode) && selectedCountry != 'XX') {
      value = countryCode + value;
    }
    this.value = value;
  });

  // Define arrays to keep track of added product IDs and option IDs
  var addedProductIds = [];
  var addedOptionIds = [];

  // Define a flag to check if the product has already been appended
  var productAppended = false;

  $('.comparative-button').on('click', function () {
    var optionIds = [];
    $('.option-list__row .option-list__col').each(function () {
      if ($(this).find('label').hasClass('checked')) {
        var optionId = $(this).data('option-id');
        optionIds.push(optionId);
      }
    });
 
    reloadRecycleBin($('.comparative-popup__main_cart.comparative-popup__block'));
    // $('#comparative-popup').load(location.href + ' .comparative-popup__main_cart .dof-cart__product__inner .dof-cart__product__price', function () { });
    // Check if optionIds have changed or if products haven't been appended yet
    if (!arraysEqual(optionIds, addedOptionIds) || !productAppended) {
      // Update the addedOptionIds list to match the current optionIds
      addedOptionIds = optionIds.slice(0);

      // Reset addedProductIds list
      addedProductIds = [];

      // Fetch products based on the current optionIds
      const currentProductId = $(this).data('product-id');
      $.ajax({
        url: wc_add_to_cart_params.ajax_url,
        type: 'POST',
        data: {
          action: 'dof_second_cart',
          current_product_id: currentProductId,
          option_ids: optionIds,
        },
        success: function (response) {
          if (response.success) {
            var productData = response.data;
            // Clear the previous products
            $('.comparative-popup__second_cart .dof-cart__products .comparative-popup__products').empty();
            for (var i = 0; i < productData.products.length; i++) {
              var product = productData.products[i];
              var productHtml = '<div class="dof-cart__product__inner product-item ' + product.main_product + product.cart_enable_warranty + '" ' +
                'data-product-type="' + product.product_type + '" ' +
                'shipping-attribute="' + product.shipping_attribute + '" ' +
                'shipping-product-crm="' + product.shipping_product_crm + '" ' +
                'data-enable-warranty-percentage="' + product.warranty_percentage + '" ' +
                'data-enable-warranty-cart="' + product.enable_warranty + '" ' +
                'data-product-id="' + product.id + '" data-quantity="1">';

              productHtml += '<div class="dof-cart__product__image">' + product.image + '</div>';

              productHtml += '<div class="dof-cart__product__info">' +
                '<h6>' + product.title + '</h6>' +
                '<div class="dof-cart__product_subtext">' + product.product_name + '</div>' +
                '</div>';

              productHtml += '<div class="dof-cart__product__price">'+productData.currency_symbol + product.price + '</div>' +
                '<div class="dof-cart__remove">' +
                '<button class="cart-remove"' +
                'data-cart-remove-product-id="' + product.id + '">×</button>' +
                '</div>';

              productHtml += '</div>';

              // Append the product HTML
              $('.comparative-popup__second_cart .dof-cart__products .comparative-popup__products').append(productHtml);

              // Add the product ID to the addedProductIds list
              addedProductIds.push(product.id);
            }
            let warrantyPrice = calculateSecondCartUpdatePrices();

            let totalPriceWithWarranty = warrantyPrice ? productData.total_price + warrantyPrice : productData.total_price;
            $('.comparative-popup__second_cart .dof-cart__products .subtotal .woocommerce-Price-amount bdi').html('<span class="woocommerce-Price-currencySymbol">'+productData.currency_symbol+'</span>' + Math.round(totalPriceWithWarranty));
            productAppended = true;
          } else {
            console.log('Error: ' + response.data);
          }
        },
      });
    }
  });

  // Function to compare two arrays
  function arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }

  $('.comparative-popup__block').on('click', function (event) {
    // If the clicked element is .cart-remove, do nothing
    if ($(event.target).hasClass('cart-remove')) {
      return;
    }
    // Remove radio-active class from all blocks first
    $('.comparative-popup__block').removeClass('radio-active');
    // Add radio-active class to the clicked block
    $(this).addClass('radio-active');
    // Find the radio button within this block
    var radioButton = $(this).find('input[type="radio"]');
    // Check if the radio button is not already checked
    if (!radioButton.prop("checked")) {
      // Uncheck all radio buttons
      $('input[type="radio"][name="option"]').prop("checked", false);
      // Check the radio button within this block
      radioButton.prop("checked", true);
      // Remove the disabled attribute and class from the button
      $('.comparative-popup__disabled').removeAttr('disabled').removeClass('comparative-popup__disabled');
    }

    // Determine which cart type class to add
    if ($(this).hasClass('comparative-popup__second_cart')) {
      $('.comparative-popup__second_cart').removeClass('second_cart_checked');
      $(this).addClass('second_cart_checked');
      $('.comparative-popup__main_cart').removeClass('main_cart_checked');
    } else if ($(this).hasClass('comparative-popup__main_cart')) {
      $('.comparative-popup__main_cart').removeClass('main_cart_checked');
      $(this).addClass('main_cart_checked');
      $('.comparative-popup__second_cart').removeClass('second_cart_checked');
    }
  });

  $('.comparative-popup__checkout button').on('click', function (e) {
    e.preventDefault();
    const currentURL = window.location.protocol + '//' + window.location.hostname;
    var productIDs = [];

    // Iterate through each element with the class 'dof-cart__product__inner'
    $('.radio-active .dof-cart__product__inner').each(function () {
      // Get the value of the 'data-product-id' attribute for each element
      var productID = $(this).data('product-id');
      // Push the product ID into the array
      productIDs.push(productID);

    });

    // Iterate through the productIDs array
    productIDs.forEach(function (productID) {
      // Check if the current product ID is not in localStorage
      if (!localStorage.getItem('addToCartCheckboxState_' + productID)) {
        // Uncheck the addToCart checkbox
        $('#addToCartCheckbox').prop('checked', false);
        // Update local storage with the modified checkbox state (unchecked)
        localStorage.setItem('addToCartCheckboxState_' + productID, 'unchecked');
      }
      if (localStorage.getItem('addToCartCheckboxState_' + productID)) {    	    
    	  localStorage.setItem('addToCartCheckboxState_'+productID, 'checked');
      }
    });    
    $.ajax({
      type: 'POST',
      url: wc_add_to_cart_params.ajax_url, // WooCommerce AJAX URL
      data: {
        action: 'popup_add_to_cart',
        product_ids: productIDs
      },
      success: function (response) {
        if (response.success === true) {
          // Clear all items from the localStorage
          // localStorage.clear();
          // Redirect the user to the checkout page
  	      window.dataLayer = window.dataLayer || [];
	      window.dataLayer = addGoogleEvent(window.dataLayer,response.data.productId,response.data.simulator_name,response.data.simulator_price);	      

          window.location.href = currentURL + '/checkout';
        } else {
          // Handle the case when the response indicates failure
          console.log('AJAX request failed.');
        }
      }
    });
  });


  function addToCartPopup() {
    var productIDs = [];

    // Iterate through each element with the class 'dof-cart__product__inner'
    $('.radio-active .dof-cart__product__inner').each(function () {
      // Get the value of the 'data-product-id' attribute for each element
      var productID = $(this).data('product-id');
      // Push the product ID into the array
      productIDs.push(productID);

    });
    // Iterate through the productIDs array
    productIDs.forEach(function (productID) {
      // Check if the current product ID is not in localStorage
      if (!localStorage.getItem('addToCartCheckboxState_' + productID)) {
        // Uncheck the addToCart checkbox
        $('#addToCartCheckbox').prop('checked', false);
        // Update local storage with the modified checkbox state (unchecked)
        localStorage.setItem('addToCartCheckboxState_' + productID, 'unchecked');
      }
    });

    $.ajax({
      type: 'POST',
      url: wc_add_to_cart_params.ajax_url, // WooCommerce AJAX URL
      data: {
        action: 'popup_add_to_cart',
        product_ids: productIDs
      },
      success: function (response) {
        if (response.success === true) {
          window.location.reload();
          // Clear all items from the localStorage
          // localStorage.clear();
          // Redirect the user to the checkout page
          // window.location.href = currentURL + '/checkout';
        } else {
          // Handle the case when the response indicates failure
          console.log('AJAX request failed.');
        }
      }
    });
  }

  // Bind function to close the popup when the close button is clicked
  $('#comparative-popup').on('click', '.mfp-close', function () {
    if ($('.comparative-popup__block').hasClass('radio-active')) {
      addToCartPopup();
    }
    $.magnificPopup.close();
  });

  $(document).on('click', function (event) {
    if ($(event.target).hasClass('cart-remove')) {
      return;
    }
    if (!$(event.target).closest('#comparative-popup').length) {
      let radioActive = $('.comparative-popup__open').find('.comparative-popup__block').hasClass('radio-active');
      if (radioActive) {
        // Allow closing the popup if the radio is active
        addToCartPopup();
        $.magnificPopup.close();
      }
      // Prevent propagation to avoid interference with other click events
      event.stopPropagation();
    }
  });

  // Attach event listener to handle cart item removal and update total amount
  $('.comparative-popup__second_cart .dof-cart__products').on('click', '.cart-remove', function () {	  
    var $productItem = $(this).closest('.product-item');
    // Get the product ID to be removed
    var productIdToRemove = $(this).data('cart-remove-product-id');
    // Remove the product block from the DOM
    var removedProduct = $(this).closest('.dof-cart__product__inner');
    removedProduct.remove();
    localStorage.setItem('addToCartCheckboxState_'+productIdToRemove, 'unchecked');
    var currency = $('#comparative-popup').data('currency');
    // Remove the 'checked' class from the removed product
    removedProduct.removeClass('checked');
    // Check if the product item has the class 'cart-enable_warranty'
    var isWarrantyProduct = $productItem.hasClass('cart-enable_warranty');
    // Remove the product ID from addedProductIds array
    var indexToRemove = addedProductIds.indexOf(productIdToRemove);
    if (indexToRemove !== -1) {
      addedProductIds.splice(indexToRemove, 1);
    }
    // Calculate the total amount
    var totalAmount = 0;
    var classEnableWarranty = false;
    $('.comparative-popup__second_cart .dof-cart__products .dof-cart__product__price').each(function () {

      var $productItemInner = $(this).closest('.product-item');
      // Check if the product has the class cart-enable_warranty
      if (!$productItemInner.hasClass('cart-enable_warranty')) {
        var priceString = $(this).text().replace(currency, '').replace(/\s/g, ''); // Remove currency symbol
        var price = parseFloat(priceString);
        if (!isNaN(price)) {
          var shipping = $(this).parent().attr('shipping-product-crm');
          totalAmount += Math.round(price-shipping); // Round the price and add to totalAmount
        }
      }
      if ($productItemInner.hasClass('cart-enable_warranty')) {
        classEnableWarranty = true;
      }
    });

    if (!isWarrantyProduct && classEnableWarranty) {
      let returnSecondPrice = calculateSecondUpdatePrices(totalAmount);
      totalAmount += returnSecondPrice;
    }

    // Update the total amount display
    $('.comparative-popup__second_cart .dof-cart__products .subtotal .woocommerce-Price-amount bdi').text(currency + totalAmount.toFixed(0));

    // Check if addToCart checkbox state is checked in local storage
    // var checkboxState = localStorage.getItem('addToCartCheckboxState_' + productIdToRemove);
    // if (checkboxState === 'checked') {
    //   // Uncheck the addToCart checkbox
    //   $('#addToCartCheckbox').prop('checked', false);
    //   // Update local storage with the modified checkbox state
    //   localStorage.setItem('addToCartCheckboxState_' + productIdToRemove, 'unchecked');
    // }
    // Check if there are no more items left
    if ($('.comparative-popup__second_cart .dof-cart__products .dof-cart__product__inner').length === 0) {
      // Reload the page
      location.reload();
    }
  });
  function bindMainRemoveClick() {
	  $('.comparative-popup__main_cart .dof-cart__products').on('click', '.cart-remove', function () {
		  var $productItem = $(this).closest('.product-item');
		  // Get the product ID to be removed
		  var productIdToRemove = $(this).data('cart-remove-product-id');
		  const $this = $(this);
		  // 	Remove the product block from the DOM
		  var removedProduct = $(this).closest('.dof-cart__product__inner');
		  removedProduct.remove();
		  // 	Remove the 'checked' class from the removed product
		  removedProduct.removeClass('checked');
		  // Check if the product item has the class 'cart-enable_warranty'
		  var isWarrantyProduct = $productItem.hasClass('cart-enable_warranty');
		  // Remove the product ID from addedProductIds array
		  var indexToRemove = addedProductIds.indexOf(productIdToRemove);
		  if (indexToRemove !== -1) {
			  addedProductIds.splice(indexToRemove, 1);
		  }
		  localStorage.setItem('addToCartCheckboxState_'+productIdToRemove, 'unchecked');
		  var currency = $('#comparative-popup').data('currency');
		  // Calculate the total amount
		  var totalAmount = 0;
		  var classEnableWarranty = false;
		  $('.comparative-popup__main_cart .dof-cart__products .product-item .dof-cart__product__price').each(function () {
			  var $productItemInner = $(this).closest('.product-item');
			  // Check if the product has the class cart-enable_warranty
			  if (!$productItemInner.hasClass('cart-enable_warranty')) {
    	  
				  var priceString = $(this).text().replace(currency, '').replace(/\s/g, ''); // Remove currency symbol
				  var price = parseFloat(priceString);
				  if (!isNaN(price)) {
					  var shipping = $(this).parent().attr('shipping-product-crm');            
					  totalAmount += Math.round(price-shipping); // Round the price and add to totalAmount
				  }
			  }
			  if ($productItemInner.hasClass('cart-enable_warranty')) {
				  classEnableWarranty = true;
			  }
		  	});
		  if (!isWarrantyProduct && classEnableWarranty) {
			  let returnSecondPrice = calculateMainUpdatePrices(totalAmount);
			  totalAmount += returnSecondPrice;
		  }
		  // $this.parent().load(location.href + ' .dof-cart__product__inner', function () { });
		  // Update the total amount display   
		  $('.comparative-popup__main_cart').find('.subtotal').text(currency + totalAmount);
		  // Check if there are no more items left
		  if ($('.comparative-popup__main_cart .dof-cart__products .dof-cart__product__inner').length === 0) {
			  // Reload the page
			  location.reload();
		  }
	  });
  }
  function calculateMainUpdatePrices(priceSum) {
    // Calculate the percentage amount
    var warrantyPercentage = parseFloat($('.dof-cart__products').find('.cart-enable_warranty').data('enable-warranty-percentage'));
    var updatedPrice = (priceSum * warrantyPercentage) / 100;
    var currency = $('#comparative-popup').data('currency');
    $('.comparative-popup__main_cart .dof-cart__products').find('.cart-enable_warranty .dof-cart__product__price').text(currency + Math.round(updatedPrice));
    return Math.round(updatedPrice); // Return the updated price
  }

  function calculateSecondUpdatePrices(priceSum) {

    // Calculate the percentage amount
    var warrantyPercentage = parseFloat($('.dof-cart__products').find('.cart-enable_warranty').data('enable-warranty-percentage'));      
    var updatedPrice = (priceSum * warrantyPercentage) / 100;
    var currency = $('.dof-cart__products').data('currency');
    if(!currency) var currency =$('.inner-product').data('currency');
    $('.comparative-popup__second_cart .dof-cart__products').find('.cart-enable_warranty .dof-cart__product__price').text(currency + Math.round(updatedPrice));
    return Math.round(updatedPrice); // Return the updated price
  }

  if(!$('.remove-product').attr("class"))addPopupProduct();
  
  $('.single_add_to_cart_button_buy_now').on('click',  function () {
	 if(!$('.remove-product').attr("class"))
	     $("#open-add-to-cart-popup").trigger('click');	      
	  else
	     location.href="/checkout/";	   
 });	     
  
  
  // Add option to cart
  $('.single_option_add_to_cart_button').on('click',function (e) {	    
	    e.preventDefault(); // Prevent the default form submission
	    const $button = $(this);
	    const productId = $button.data('product-id');
	    if($button.parent().hasClass("remove-item")){	    	
	    	 removeFromCartOption(productId,$button);
	    }else{
	    const currentProductId = $('#current_product_id').val();
	    const productType = $button.data('product-type');
	    const $messageContainer = $('.single-upgrades__add-cart').find('.add-to-cart-message');
	    // const option = $('.option-' + productId);
	    const option = $('.option-list__row');
	    showPreloader();
	    $.ajax({
			      type: 'POST',
			      url: wc_add_to_cart_params.ajax_url,
			      data: {
			        action: 'dof_add_to_cart',
			        product_id: productId,
			        current_product_id: currentProductId,
			        product_type: productType,
			      },
			      success: function (response) {

			        // Update the cart count in the header
			        if (response.success) {
			          
			          $messageContainer.html('<p>' + response.data.message + '</p>');
			          $messageContainer.fadeIn().delay(2000).fadeOut();
			          // Load the updated content of the header-item-right block
			          $('.header-item-right').load(location.href + ' .header-item-right');
			          $button.find(".option__text").text('Remove Item');
			          $button.parent().addClass('remove-item');			        
  		              localStorage.setItem(`addToCartCheckboxState_${productId}`, 'checked');
			        } else {
			        	 $messageContainer.html('<p class="error">' + response.data.message + '</p>');
		                 $messageContainer.fadeIn().delay(2000).fadeOut();
			        }
			        hidePreloader();
			      },
	    });
	    }
  });
  
  if($(".single_option_add_to_cart_button")){
	  const productId = $(".single_option_add_to_cart_button").data("product-id");
      const savedState = localStorage.getItem(`addToCartCheckboxState_${productId}`);          
      if (savedState === 'checked') { 
    	  $(".single_option_add_to_cart_button").find(".option__text").text('Remove Item');
    	  $(".single_option_add_to_cart_button").parent().addClass('remove-item');
      }
      $(".single_option_add_to_cart_button").parent().show();
  }
 		

  function removeFromCartOption(productId,button) {
      const currentProductId = $('.site-content').find('.product').data('current-product-id');
      $.ajax({
          url: wc_add_to_cart_params.ajax_url,
          type: 'POST',
          data: {
              action: 'remove_from_cart',
              product_id: productId,
              current_product_id: currentProductId,
          },
          success: function (response) {        	  
        	  $(button).find(".option__text").text('Add to Cart');
	          $(button).parent().removeClass('remove-item');	     
	          localStorage.setItem(`addToCartCheckboxState_${productId}`, "unchecked");
	          $('.header-item-right').load(location.href + ' .header-item-right');
  	          $('.sliding-bar__btn').load(location.href + ' .sliding-bar__btn');
          }
      });
  }
  function calculateMainCartUpdatePrices() {
    var priceSum = 0;
    var currency = $('.dof-cart__products').data('currency');
    if(!currency) var currency =$('.inner-product').data('currency');
    $('.comparative-popup__main_cart .dof-cart__products .product-item:not(.cart-enable_warranty)').each(function () {
      var $productItem = $(this);
      // Extract the price text and remove any spaces before converting to float
      var priceText = $productItem.find('.dof-cart__product__price').text().replace(/\s/g, '');
      var price = parseFloat(priceText.replace(currency, '').trim());
      var shipping = $(this).attr('shipping-product-crm');
      priceSum += price-shipping;
    });

    // Calculate the percentage amount
    var warrantyPercentage = parseFloat($('.dof-cart__products').find('.cart-enable_warranty').data('enable-warranty-percentage'));
    var updatedMainPrice = (priceSum * warrantyPercentage) / 100;
    // Update the displayed price with the calculated warranty price
    $('.comparative-popup__main_cart .dof-cart__products').find('.cart-enable_warranty .dof-cart__product__price').text(currency + Math.round(updatedMainPrice));
  }

  function calculateSecondCartUpdatePrices() {
    var priceSum = 0;
    var currency = $('.dof-cart__products').data('currency');
    if(!currency) var currency =$('.inner-product').data('currency');
    $('.comparative-popup__second_cart .dof-cart__products .product-item:not(.cart-enable_warranty)').each(function () {
      var $productItem = $(this);
      // Extract the price text and remove any spaces before converting to float
      var priceText = $productItem.find('.dof-cart__product__price').text().replace(/\s/g, '');
      var price = parseFloat(priceText.replace(currency, '').trim());
      var shipping = $(this).attr('shipping-product-crm');
      priceSum += price-shipping;
    });

    // Calculate the percentage amount
    var warrantyPercentage = parseFloat($('.comparative-popup__second_cart .dof-cart__products').find('.cart-enable_warranty').data('enable-warranty-percentage'));
    var updatedPrice = (priceSum * warrantyPercentage) / 100;
    $('.comparative-popup__second_cart .dof-cart__products').find('.cart-enable_warranty .dof-cart__product__price').text(currency + Math.round(updatedPrice));
    // Return the updated price
    return updatedPrice;
  }

  
  $(document).on('click', '#place_order', function () {	  
	  var account_code = $('input[name="payment_method"]:checked').data('account-code');
	  setCookie("account_code", account_code);
	  var items=[];
	  var currency = $(".dof-cart__products").data('currency');
  	  $(".dof-cart__products .product-item").each(function(){
  	  var productName = $(this).find('.dof-cart__product__info h6').text(); // Assuming you have a data attribute for product ID
      var productPrice = $(this).find('.dof-cart__product__price').text(); // Assuming you have a data attribute for product ID
      var productPriceAmount = Math.round(parseFloat(productPrice.replace(currency, '').trim()));
      var productID = $(this).attr("data-product-id"); // Assuming you have a data attribute for product ID
          
  		var item = {
  				item_name: productName, // ім'я товару має бути однією мовою незалежно від мовної версії на сайті
					item_id: productID, // номер товару
					price: productPriceAmount, // ціна товару
					quantity: 1
  		};
  		items.push(item);
  		localStorage.setItem('addToCartCheckboxState_'+productID, 'unchecked');    
  	});    	
		window.dataLayer = window.dataLayer || [];
  		dataLayer.push({ 
  			event: "checkout_step4",
  			ecommerce: {
  				items: items    					
  			}
  		});
	  $.ajax({
          type: 'POST',
          url: ajax_order_params.ajax_url,
          data: {
              action: 'insert_woo_ajax', // Create a custom AJAX action to clear the cart
              account_code: account_code
          },
          success: function (cartResponse) {
          },
          error: function (cartError) {
          }
      });	  
	  localStorage.removeItem("utm_campaign");
      localStorage.removeItem("source");
      localStorage.removeItem("rpc");
      localStorage.removeItem("utm_medium");
      localStorage.removeItem("utm_content");         
	  //localStorage.clear();    	  
  });


  function reloadRecycleBin(parent) {
    $.ajax({
      url: wc_add_to_cart_params.ajax_url,
      type: 'POST',
      data: {
        action: 'reload_recycle_bin'
      },
      success: function (response) {
        // Update recycle bin content with the response
        $(parent).html(response);
        bindMainRemoveClick();
        calculateMainCartUpdatePrices();
      },
      error: function (xhr, status, error) {
        console.error(status + ": " + error);
      }
    });
  }
 
  function addGoogleEvent(dataLayer,item_id,item_name,item_price) {
	  dataLayer.push({  
		  	event: "add_to_cart",
		  	ecommerce: {
		  		items: [{
		  			item_name: "Donut Friday Scented T-Shirt", // ім'я товару має бути однією мовою незалежно від мовної версії на сайті
		  			item_id: "67890", // номер товару
		  			price: "33.75", // ціна товару
		  			quantity: 1
		  		}]
		  	}
	  });
	  return dataLayer; 
  }



}); 