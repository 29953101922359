import $ from "jquery";
import 'jquery-mask-plugin';
import "magnific-popup/dist/jquery.magnific-popup.min";

import smartWizard from 'smartwizard';
// import inputmask from './';
$(function () {
	let is_step_storage = 0;		
    $('#newsletter_checkbox').prop('checked', true);
    localStorage.removeItem('country');
    var currency = $(".dof-cart__products").data('currency');
    
    // $('form.checkout input[name="payment_method"]').prop('checked', false);
    setTimeout(function () {
        // Unselect the default payment method
        $('form.checkout input[name="payment_method"]').prop('checked', false);
        $('form.checkout input[name="payment_method"]').removeAttr('checked');
        // If payment methods are within an accordion, close the accordion
        $('.woocommerce-checkout-payment').find('.payment_box').slideUp(); // Adjust the selector based on your theme
    }, 1000);


    if ($('#newsletter_checkbox').is(':checked')) {
        $('#newsletter_checkbox').parent().addClass('checked-terms');
    }
    
    if($(".checkout .dof-cart__products .product-item") && (localStorage.getItem("checkoutCurrentStep")==0 || !localStorage.getItem("checkoutCurrentStep"))){    	
    	var items=[];    	
    	$(".dof-cart__products .product-item").each(function(){
    	    var productName = $(this).find('.dof-cart__product__info h6').text(); // Assuming you have a data attribute for product ID
            var productPrice = $(this).find('.dof-cart__product__price').text(); // Assuming you have a data attribute for product ID
            var productPriceAmount = Math.round(parseFloat(productPrice.replace(currency, '').trim()));
            var productID = $(this).attr("data-product-id"); // Assuming you have a data attribute for product ID
            
    		var item = {
    				item_name: productName, // ім'я товару має бути однією мовою незалежно від мовної версії на сайті
					item_id: productID, // номер товару
					price: productPriceAmount, // ціна товару
					quantity: 1
    		};
    		items.push(item);
    	});    	
    	
    	window.dataLayer = window.dataLayer || [];
    	dataLayer.push({ 
    		event: "checkout_step1",
    		ecommerce: {
    			items: items    					
    		}
    	});
    }
    
    // Add a class to the parent when checkbox is checked
    $('#newsletter_checkbox').on('change', function () {
        if ($(this).is(':checked')) {
            $(this).parent().addClass('checked-terms');
        } else {
            $(this).parent().removeClass('checked-terms');
        }
    });
    // Toggle the shipping address fields when the checkbox is clicked
    $('#agree_terms_field').on('change', function () {
        $(this).parent().toggleClass('checked-terms');
    });


    function customizePaymentMethodRadio() {
        // Add the class to the initially checked radio button
        $('#payment input.input-radio:checked').closest('li').addClass('input-radio-checked');

        // Listen for changes in the radio buttons
        $('#payment .payment_methods li input').on('change', function () {
            // Remove the 'checked' attribute from all radio buttons
            $('#payment .payment_methods li input').removeAttr('checked');

            // Remove the class from all <li> elements
            $('ul.payment_methods li').removeClass('input-radio-checked');

            // Add the class and 'checked' attribute to the parent <li> of the checked radio button
            if ($(this).is(':checked')) {
                $(this).attr('checked', 'checked');
                $(this).closest('li').addClass('input-radio-checked');
            }
        });
    }

    
    setTimeout(function () {
        var $paymentMethod = $('li.payment_method_stripe ');
        $paymentMethod.find('label[for="payment_method_stripe"] img').wrapAll('<div class="strip-payment-method-wrap"></div>');
    }, 1000);


    $('input[name="billing_first_name"]').on('blur', function () {
        var fieldValue = $(this).val();
        if (fieldValue === '') {
            $(this).siblings('.error-message').text('This field is required.');
        } else {
            $(this).siblings('.error-message').text('');
        }
    });


    var form_data = {}; // Collect form data
    var wizard = $('#dof-checkout__smartwizard').smartWizard({
        transitionEffect: 'slide',
        progressbar: false,
        enableUrlHash: false,
        toolbar: {
            position: 'bottom',
            showNextButton: false,
            showPreviousButton: false,
            extraHtml: ''
        },
        keyboard: {
            keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
            keyLeft: [37], // Left key code
            keyRight: [39] // Right key code
        },
    });
        
    // Function to validate step-1 fields
    function validateStep1Fields() {
        var requiredFields = $('#step-1 .validate-required');
        var hasEmptyRequiredFields = false;

        requiredFields.each(function () {
            var field = $(this).find('input, select, textarea');
            if (field.val() === '') {
                hasEmptyRequiredFields = true;
                field.addClass('missing-data');
                $(this).addClass('woocommerce-invalid');
            } else if (field.attr('name') === 'billing_email') {            	
                // Check for valid email format
                if (!isValidEmail(field.val())) {
                    hasEmptyRequiredFields = true;
                    field.addClass('missing-data');
                    field.addClass('invalid-email');
                    $(this).addClass('woocommerce-invalid');
                }
            }
        });



        if (!hasEmptyRequiredFields) {
            $('#step-1').removeClass('active'); // Remove active class from step-1
            $('#step-2').addClass('active'); // Add active class to step-2
        }

        return !hasEmptyRequiredFields;
    }

    // Add an event listener to the ship-to-different-address checkbox
    $('#ship-to-different-address-checkbox').on('change', function () {
        // Check if the checkbox is checked    
        var isShipToDifferentAddress = $(this).is(':checked');
        var currentStepId = $('.tab-pane.active').attr('id');
        $('#' + currentStepId).addClass('active');
        // Check Step 2 fields including shipping address only when ship-to-different-address is checked
        if (isShipToDifferentAddress) {
            var isValidStep2Fields = validateStep2Fields();

            if (!isValidStep2Fields) {
                // Display an error message or perform any desired action
                alert('Please fill in all required Step 2 fields including shipping address.');
                // Optionally, you can prevent further processing here or take other actions.
            }
        } else {
            // If the checkbox is unchecked (billing address is same as shipping address), re-add the "active" class to the current step
            var currentStepId = $('.tab-pane.active').attr('id');
            $('#' + currentStepId).addClass('active');
        }

    });

    const countryCodes = {
        'US': '+1',
        'AR': '+54',
        'AM': '+374',
        'AU': '+61',
        'AT': '+43',
        'BE': '+32',
        'BR': '+55',
        'BG': '+359',
        'CA': '+1',
        'CL': '+56',
        'CN': '+86',
        'HR': '+385',
        'CY': '+357',
        'CZ': '+420',
        'DK': '+45',
        'EG': '+20',
        'EE': '+372',
        'FI': '+358',
        'FR': '+33',
        'DE': '+49',
        'GR': '+30',
        'HK': '+852',
        'HU': '+36',
        'IS': '+354',
        'IN': '+91',
        'ID': '+62',
        'IE': '+353',
        'IL': '+972',
        'IT': '+39',
        'JP': '+81',
        'JO': '+962',
        'KE': '+254',
        'KW': '+965',
        'LV': '+371',
        'LT': '+370',
        'LU': '+352',
        'MY': '+60',
        'MX': '+52',
        'NL': '+31',
        'NZ': '+64',
        'NO': '+47',
        'PK': '+92',
        'PE': '+51',
        'PH': '+63',
        'PL': '+48',
        'PT': '+351',
        'QA': '+974',
        'RO': '+40',
        'RU': '+7',
        'SA': '+966',
        'SG': '+65',
        'SK': '+421',
        'SI': '+386',
        'ZA': '+27',
        'KR': '+82',
        'ES': '+34',
        'SE': '+46',
        'CH': '+41',
        'TW': '+886',
        'TH': '+66',
        'TR': '+90',
        'AE': '+971',
        'GB': '+44',
        'XX': '+XX' // Add a default country code for unrecognized countries
    };
    const masks = {
        'US': "\\+1\\d{10}",
        'AR': "\\+54\\d{9}",
        'AM': "\\+374\\d{8}",
        'AU': "\\+61\\d{9}",
        'AT': "\\+43\\d{9}",
        'BE': "\\+32\\d{8,9}",
        'BR': "\\+55\\d{10,11}",
        'BG': "\\+359\\d{8,9}",
        'CA': "\\+1\\d{10}",
        'CL': "\\+56\\d{9}",
        'CN': "\\+86\\d{10,11}",
        'HR': "\\+385\\d{8,9}",
        'CY': "\\+357\\d{8}",
        'CZ': "\\+420\\d{9}",
        'DK': "\\+45\\d{8}",
        'EG': "\\+20\\d{10,11}",
        'EE': "\\+372\\d{7,8}",
        'FI': "\\+358\\d{9,10}",
        'FR': "\\+33\\d{9}",
        'DE': "\\+49\\d{10,11}",
        'GR': "\\+30\\d{10,11}",
        'HK': "\\+852\\d{8}",
        'HU': "\\+36\\d{8,9}",
        'IS': "\\+354\\d{7}",
        'IN': "\\+91\\d{10}",
        'ID': "\\+62\\d{9,12}",
        'IE': "\\+353\\d{8}",
        'IL': "\\+972\\d{9}",
        'IT': "\\+39\\d{10,11}",
        'JP': "\\+81\\d{10}",
        'JO': "\\+962\\d{7,9}",
        'KE': "\\+254\\d{9}",
        'KW': "\\+965\\d{8}",
        'LV': "\\+371\\d{8}",
        'LT': "\\+370\\d{8}",
        'LU': "\\+352\\d{6,9}",
        'MY': "\\+60\\d{9,10}",
        'MX': "\\+52\\d{10,12}",
        'NL': "\\+31\\d{9}",
        'NZ': "\\+64\\d{8,9}",
        'NO': "\\+47\\d{8,9}",
        'PK': "\\+92\\d{10}",
        'PE': "\\+51\\d{9}",
        'PH': "\\+63\\d{10}",
        'PL': "\\+48\\d{9,10}",
        'PT': "\\+351\\d{9}",
        'QA': "\\+974\\d{7,8}",
        'RO': "\\+40\\d{9}",
        'RU': "\\+7\\d{10,11}",
        'SA': "\\+966\\d{9}",
        'SG': "\\+65\\d{8}",
        'SK': "\\+421\\d{9}",
        'SI': "\\+386\\d{8}",
        'ZA': "\\+27\\d{9,10}",
        'KR': "\\+82\\d{9,11}",
        'ES': "\\+34\\d{9}",
        'SE': "\\+46\\d{9,11}",
        'CH': "\\+41\\d{9}",
        'TW': "\\+886\\d{9,10}",
        'TH': "\\+66\\d{9,10}",
        'TR': "\\+90\\d{10}",
        'AE': "\\+971\\d{9}",
        'GB': "\\+44\\d{10,11}",
        // 'XX': "\\+XX" // Default mask for unrecognized countries
    };

    function validatePhoneNumber(phoneNumber, country) {    	
        var mask = masks[country];
        var isValidPhone = false;

        if (mask) {
            // Construct the regular expression pattern
            var regexPattern = '^' + mask + '$';

            // Create the regular expression
            var phoneRegex = new RegExp(regexPattern);

            // Validate the phone number using the regular expression
            isValidPhone = phoneRegex.test(phoneNumber);
        } else {
        	isValidPhone = true;
        }

        return isValidPhone;
    }

    /**
     * Function to validate step-2 fields
     * @returns 
     */
    function validateStep2Fields() {
        let requiredFields;
        // var requiredFields = $('#step-2 .billing-address .validate-required');
        if ($('#ship-to-different-address-checkbox').is(':checked')) {
            // Validate both billing and shipping addresses
            requiredFields = $('#step-2 .validate-required');
        } else {
            // Validate only billing address
            requiredFields = $('#step-2 .billing-address .validate-required');
        }
        var hasEmptyRequiredFields = false;

        requiredFields.each(function () {
            var field = $(this).find('input, select, textarea');
            // if (field.val() === '') {
            //     hasEmptyRequiredFields = true;
            //     field.addClass('missing-data');
            //     $(this).addClass('woocommerce-invalid');
            // }

            // if (field.val() === '') {
            //     hasEmptyRequiredFields = true;
            //     // Add a red border or any other styling to indicate the empty required field
            //     field.addClass('missing-data');
            //     $(this).addClass('woocommerce-invalid');
            // } else {
            //     // If the field is filled, remove the 'woocommerce-invalid' class
            //     $(this).removeClass('woocommerce-invalid');
            // }
            if (field.val() === '') {
                hasEmptyRequiredFields = true;
                field.addClass('missing-data');
                $(this).addClass('woocommerce-invalid');
            } else if (field.attr('name') === 'shipping_email') {
                // Check for valid email format
                if (!isValidEmail(field.val())) {
                    hasEmptyRequiredFields = true;
                    field.addClass('missing-data');
                    field.addClass('invalid-email');
                    $(this).addClass('woocommerce-invalid');
                }
            } else if (field.attr('name') === 'billing_phone') {
                var selectedCountry = localStorage.getItem('country');
                
                if (selectedCountry != 'XX') {
                    var isValidPhone = validatePhoneNumber(field.val(), selectedCountry);

                    if (!isValidPhone) {
                        hasEmptyRequiredFields = true;
                        field.addClass('missing-data');
                        field.addClass('invalid-phone');
                        $(this).addClass('woocommerce-invalid');
                    }
                }
                // else {
                //     // If selected country is XX, ensure phone number is at least 11 digits long
                //     if (field.val().length < 11) {
                //         hasEmptyRequiredFields = true;
                //         field.addClass('missing-data');
                //         field.addClass('invalid-phone');
                //         $(this).addClass('woocommerce-invalid');
                //     }
                // }
            }
        });


        let agreeTermsFieldWrap = $('#step-2 .agree-terms-field.validate-required');
        let agreeTermsField = $('#agree_terms_field');

        // if (agreeTermsField.length > 0) {
        if (!agreeTermsField.is(':checked')) {

            // Checkbox is not checked
            // Add any necessary styling to indicate the unchecked checkbox                 
            agreeTermsFieldWrap.addClass('woocommerce-invalid');
            hasEmptyRequiredFields = true;
        } else {
            // Checkbox is checked, remove any invalid state classes
            agreeTermsFieldWrap.removeClass('woocommerce-invalid');
        }

        if (!hasEmptyRequiredFields) {
            if($('.delivery-price').html() != 'Place order'){        		
        		$('#step-2').removeClass('active'); // Remove active class from step-2
        		$('#step-3').addClass('active'); // Add active class to step-2
        	}
            // Add logic for the next step (e.g., $('#step-3').addClass('active');)
        }

        return !hasEmptyRequiredFields;
    }

    // Function to remove the missing-data class and woocommerce-invalid class from all fields
    function removeValidationClasses() {
        $('.missing-data').removeClass('missing-data');
        $('.woocommerce-invalid').removeClass('woocommerce-invalid');
    }

    /**
     * Function to validate email format
     * @param {*} email 
     * @returns 
     */
    function isValidEmail(email) {
        // Use a regular expression to validate email format
        var emailPattern =/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }
    /**
     * Submits a form via fetch to the specified API URL.
     * 
     * @param {string} apiURL - The URL where the form data will be submitted.
     * @param {FormData} formData - The form data to be sent.
     * @param {function} successCallback - Callback function to handle successful response.
     * @param {function} errorCallback - Callback function to handle errors.
     */
    function submitForm(apiURL, formData, successCallback, errorCallback) {
        fetch(apiURL, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.text())
            .then(htmlResponse => {
                // Handle success
                successCallback(htmlResponse);
            })
            .catch(error => {
                // Handle errors
                errorCallback(error);
            });
    }
    function modifyPaymentLabel(paymentMethod) {
        const paymentLabel = $(`label[for="payment_method_${paymentMethod}"]`);
        if (paymentLabel.length > 0) {
            let labelContents = paymentLabel.contents();
            labelContents.each(function () {
                if (this.nodeType === Node.TEXT_NODE) {
                    const updatedText = this.nodeValue.replace(new RegExp(`\\b${paymentMethod}\\b`, 'g'), '');
                    this.nodeValue = updatedText;
                }
            });
        }
    }
    $('.send-request-btn').on('click', function () {
        if (validateStep2Fields()) {
            const currentURL = window.location.protocol + '//' + window.location.hostname;
            const apiFormURL = currentURL + '/wp-json/contact-form-7/v1/contact-forms/1286/feedback';
            const name = $("#billing_first_name").val() + ' ' + $("#billing_last_name").val();
            const email = $("#billing_email").val();
            const country = $('#billing_country option:selected').text();
            const billing_address_1 = $("#billing_address_1").val();
            const billing_address_2 = $("#billing_address_2").val();
            const billing_company = $("#billing_company").val();
            const billing_city = $("#billing_city").val();
            const billing_state = $(".billing-state-text").val();
            const billing_postcode = $("#billing_postcode").val();
            const billing_phone = $("#billing_phone").val();
            const order_comments = $("#order_comments").val();
            const total = $('.dof-checkout__cart').find('.total .woocommerce-Price-amount bdi').text();
            const totalAmount = Math.round(parseFloat(total.replace(currency, '').trim())); // Convert to a number                                        
            const postNames = [];
            $('.dof-cart__products .product-item').each(function () {
                const productName = $(this).find('.dof-cart__product__info h6').text(); // Assuming you have a data attribute for product ID
                // const productPrice = $(this).find('.dof-cart__product__price .woocommerce-Price-amount bdi').text(); // Assuming you have a data attribute for product ID
                const productPrice = $(this).find('.dof-cart__product__price').text(); // Assuming you have a data attribute for product ID
                const productPriceAmount = Math.round(parseFloat(productPrice.replace(currency, '').trim()));
                postNames.push(productName + ' - '+ currency + productPriceAmount);
            });

            const commaSeparatedNames = postNames.join(', ');
            const formData = new FormData();
            formData.append('your-name', name);
            formData.append('your-email', email);
            formData.append('country', country);
            formData.append('billing_company', billing_company);
            formData.append('billing_address_1', billing_address_1);
            formData.append('billing_address_2', billing_address_2);
            formData.append('billing_city', billing_city);
            formData.append('billing_state', billing_state);
            formData.append('billing_postcode', billing_postcode);
            formData.append('billing_phone', billing_phone);
            formData.append('order_comments', order_comments);
            formData.append('total_price', currency + totalAmount);
            // Add the list of post names to the form data
            formData.append('product_names', commaSeparatedNames);            
            submitForm(apiFormURL, formData,
                htmlResponse => {
                    // Success callback
                    // console.log('Successful!!!');
                    $.magnificPopup.open({
                        items: {
                            src: '#success-popup'
                        },
                        mainClass: 'successful-popup',
                    });
                    // Handle the click event for the "Close Popup" button
                    $('#success-popup').on('click', '.mfp-close', function () {
                        // Close the Magnific Popup
                        $.magnificPopup.close();

                        $.ajax({
                            type: 'POST',
                            url: ajax_order_params.ajax_url,
                            data: {
                                action: 'clear_cart_ajax' // Create a custom AJAX action to clear the cart
                            },
                            success: function (cartResponse) {
                                // Clear the checkout form
                                $('#checkout-form')[0].reset();
                                $('.tab-pane-info').load(location.href + ' .tab-pane-info');
                                $(".dof-checkout__cart").remove();
                                localStorage.clear();
                                window.location.href = currentURL;
                            },
                            error: function (cartError) {
                                // Handle errors related to clearing the cart
                                console.error(cartError);
                            }
                        });
                    });
                    $(document).on('click', function (e) {
                        $.ajax({
                            type: 'POST',
                            url: ajax_order_params.ajax_url,
                            data: {
                                action: 'clear_cart_ajax' // Create a custom AJAX action to clear the cart
                            },
                            success: function (cartResponse) {
                                // Clear the checkout form
                                $('#checkout-form')[0].reset();
                                $('.tab-pane-info').load(location.href + ' .tab-pane-info');
                                $(".dof-checkout__cart").remove();
                                localStorage.clear();
                                window.location.href = currentURL;
                            },
                            error: function (cartError) {
                                // Handle errors related to clearing the cart
                                console.error(cartError);
                            }
                        });
                    });

                },
                error => {
                    // Error callback
                    console.error(error);
                }
            );
        }

        // else {
        //     alert('Please fill in all required fields and provide a valid email.');
        // }
    });
    $('#checkout-form').on('checkout_place_order', function () {
        // Assume payment is successful and proceed to step 4
        $('#dof-checkout__smartwizard').smartWizard('goToStep', 3); // Go to step 4 (index starts from 0)
    });
    
    $('.next-btn').on('click', function () {      	
        const currentURL = window.location.protocol + '//' + window.location.hostname;
        // Get the ID of the current step
        var currentStepId = $('.tab-pane.active').attr('id');                
        
        var wizard = $('#dof-checkout__smartwizard');

        // Check if the current step is the first step (e.g., 'step-1')
        //const apiURLStep1 = currentURL + '/wp-json/contact-form-7/v1/contact-forms/1215/feedback';
        //const apiURLStep2 = currentURL + '/wp-json/contact-form-7/v1/contact-forms/1218/feedback';
        const name = $("#billing_first_name").val() + ' ' + $("#billing_last_name").val();
        const email = $("#billing_email").val();
        const country = $('#billing_country option:selected').text();
        const billing_address_1 = $("#billing_address_1").val();
        const billing_address_2 = $("#billing_address_2").val();
        const billing_city = $("#billing_city").val();
        const billing_state = $("#billing_state").val();
        const billing_postcode = $("#billing_postcode").val();
        const billing_phone = $("#billing_phone").val();

        /**---- Klaviyo Step 1 --- */
        const fname = $("#billing_first_name").val();
        const lname = $("#billing_last_name").val();        
		const consent = $("#newsletter_checkbox").val();
        const phone_number = $("#billing_phone").val();
        const addr_street = $("#billing_address_1").val();
        const addr_city = $("#billing_city").val();
        const addr_zip = $("#billing_postcode").val();
        const addr_state = $("#billing_state").val();
        const addr_country = $('#billing_country option:selected').text();

        /*const formData = new FormData();
        formData.append('your-name', name);
        formData.append('your-email', email);

        formData.append('country', country);
        formData.append('billing_address_1', billing_address_1);
        formData.append('billing_address_2', billing_address_2);
        formData.append('billing_city', billing_city);
        formData.append('billing_state', billing_state);
        formData.append('billing_postcode', billing_postcode);
        formData.append('billing_phone', billing_phone);*/        
        const $divElement = $('.dof-checkout__cart');        
        if (currentStepId === 'step-1') {
            // Remove validation classes from previous step
            removeValidationClasses();                        

            const formDataStep1 = new FormData();
            formDataStep1.append('your-name', name);
            formDataStep1.append('your-email', email);
            let selectedCountry = $('select#billing_country').val();
            if (!selectedCountry) {
                $('.delivery-price, .dof-tax').text('Select Shipping Country');
            }
            // Validate step-1 fields
            if (validateStep1Fields()) {               	
                // Proceed to step-2
                wizard.on("leaveStep", function (e, anchorObject, stepNumber, stepDirection) {
                    if (stepDirection === "forward") {
                        // Code to execute when moving to the next step 
                        $('.checkout-promo').css('display', 'flex');
                        $('.delivery-price').text('Select Shipping Country');
                    }
                    if (stepDirection === "forward") {
                        // Code to execute when moving to the next step
                        // console.log("Moving forward to the next step");
                    } else if (stepDirection === "backward") {
                        // Code to execute when moving backward to the previous step
                        // console.log("Going back to the previous step");
                    }
                });

                // Trigger the next step programmatically
                wizard.smartWizard('next');

                const currentStep = wizard.smartWizard("getStepInfo");
                const nextStepID = currentStep.currentStep + 1;
                // Remove any existing step class
                $divElement.removeClass(function (index, className) {
                    return (className.match(/(^|\s)step-\S+/g) || []).join(' ');
                });
                $divElement.addClass('step-' + nextStepID);
                toggleProducts();
                localStorage.setItem("checkoutCurrentStep",currentStep.currentStep);
                localStorage.setItem("billing_first_name",fname);
                localStorage.setItem("billing_last_name",lname);
                localStorage.setItem("billing_email",email);
                localStorage.setItem('newsletter_checkbox',$("#newsletter_checkbox").val());
                if(is_step_storage==0){                	
                	var items=[];
                	$(".dof-cart__products .product-item").each(function(){
                		var productName = $(this).find('.dof-cart__product__info h6').text(); // Assuming you have a data attribute for product ID
                		var productPrice = $(this).find('.dof-cart__product__price').text(); // Assuming you have a data attribute for product ID
                		var productPriceAmount = Math.round(parseFloat(productPrice.replace(currency, '').trim()));
                		var productID = $(this).attr("data-product-id"); // Assuming you have a data attribute for product ID
                    
                		var item = {
            				item_name: productName, // ім'я товару має бути однією мовою незалежно від мовної версії на сайті
        					item_id: productID, // номер товару
        					price: productPriceAmount, // ціна товару
        					quantity: 1
                		};
                		items.push(item);
                	});    	
            	
                	window.dataLayer = window.dataLayer || [];
                	dataLayer.push({ 
                		event: "checkout_step2",
                		ecommerce: {
                			items: items    					
                		}
                	});	
                }
                // Define the form data
                /*submitForm(apiURLStep1, formDataStep1,
                    htmlResponse => {
                        // Success callback
                        // console.log('Successful!!!');
                    },
                    error => {
                        // Error callback
                        console.error(error);
                    }
                );*/
                submitToKlaviyo1(email, fname, lname,consent);
            }
            // else {
            //     alert('Please fill in all required fields and provide a valid email.');
            // }


        } else if (currentStepId === 'step-2') {
            // Modify payment labels for different payment methods
            modifyPaymentLabel('stripe');
            modifyPaymentLabel('paypal');
            modifyPaymentLabel('bacs');

            removeValidationClasses();
            // Validate step-2 fields
            if (validateStep2Fields()) {
                // Proceed to step-3 or any other desired action
            	if($('.proceed-payment-btn').html() != 'Place order'){
            		wizard.smartWizard('next');
            	}else{
            		 showPreloader();
            	}
                const currentStep = wizard.smartWizard("getStepInfo");
                const nextStepID = currentStep.currentStep + 1;
                $divElement.removeClass(function (index, className) {
                    return (className.match(/(^|\s)step-\S+/g) || []).join(' ');
                });
                $divElement.addClass('step-' + nextStepID);                               
                localStorage.setItem('billing_country',$('#billing_country').val());
                localStorage.setItem('billing_address_1',$("#billing_address_1").val());
                localStorage.setItem('billing_address_2',$("#billing_address_2").val());
                localStorage.setItem('billing_city',$("#billing_city").val());
                localStorage.setItem('billing_state',$("#billing_state").val());
                localStorage.setItem('billing_postcode',$("#billing_postcode").val());
                localStorage.setItem('billing_phone',$("#billing_phone").val());  
                localStorage.setItem('billing_company',$('#billing_company').val());
                localStorage.setItem('agree_terms_field',$('#agree_terms_field').val());    
                togglePlaceOrderButton();
                var items=[];
            	$(".dof-cart__products .product-item").each(function(){
            	    var productName = $(this).find('.dof-cart__product__info h6').text(); // Assuming you have a data attribute for product ID
                    var productPrice = $(this).find('.dof-cart__product__price').text(); // Assuming you have a data attribute for product ID
                    var productPriceAmount = Math.round(parseFloat(productPrice.replace(currency, '').trim()));
                    var productID = $(this).attr("data-product-id"); // Assuming you have a data attribute for product ID
                    
            		var item = {
            				item_name: productName, // ім'я товару має бути однією мовою незалежно від мовної версії на сайті
        					item_id: productID, // номер товару
        					price: productPriceAmount, // ціна товару
        					quantity: 1
            		};
            		items.push(item);
            	});    	
            	
            	window.dataLayer = window.dataLayer || [];
            	dataLayer.push({ 
            		event: "checkout_step3",
            		ecommerce: {
            			items: items    					
            		}
            	});
                /*submitForm(apiURLStep2, formData,
                    htmlResponse => {
                        // Success callback
                        // console.log('Successful!!!');
                    },
                    error => {
                        // Error callback
                        console.error(error);
                    }
                );*/
                //submitToKlaviyo2(email, fname, lname, phone_number, addr_street, addr_city, addr_zip, addr_state, addr_country);
            }

            // else {
            //     alert('Please fill in all required fields for step-2.');
            // }
        } else {
            // Handle other steps here
        }
    });



    $('.prev-btn').on('click', function () {
        const $divElement = $('.dof-checkout__cart');
        wizard.smartWizard("prev");
        var currentStepId = $('.tab-content .tab-pane.active').attr('id');
        $('#' + currentStepId).removeClass('active')
        // Add the "active" class to the previous step
        var prevStepId = $('#' + currentStepId).prev().attr('id');
        $('#' + prevStepId).addClass('active');
        let selectedCountry = $('select#billing_country').val();
        if (!selectedCountry) {
            $('.delivery-price, .dof-tax').text('Calculated at next step');
        }
        $divElement.removeClass(function (index, className) {
            return (className.match(/(^|\s)step-\S+/g) || []).join(' ');
        });        
        if(prevStepId=="step-2"){
        	$('.dof-cart__product__inner').each(function () {
        		var href=$(this).find('a').attr("data-url");
        		$(this).find('a').attr("href",href);
        		$(this).find('a').attr("target","blank");
        		$(this).find('a').removeClass("no-link");
        	});
        	$(".dof-cart__remove").show();
        }
        $divElement.addClass(prevStepId);
        var prev_step = prevStepId.split('-');        
        localStorage.setItem("checkoutCurrentStep",(prev_step[1]-1));
        
        var items=[];
    	$(".dof-cart__products .product-item").each(function(){
    	    var productName = $(this).find('.dof-cart__product__info h6').text(); // Assuming you have a data attribute for product ID
            var productPrice = $(this).find('.dof-cart__product__price').text(); // Assuming you have a data attribute for product ID
            var productPriceAmount = Math.round(parseFloat(productPrice.replace(currency, '').trim()));
            var productID = $(this).attr("data-product-id"); // Assuming you have a data attribute for product ID
            
    		var item = {
    				item_name: productName, // ім'я товару має бути однією мовою незалежно від мовної версії на сайті
					item_id: productID, // номер товару
					price: productPriceAmount, // ціна товару
					quantity: 1
    		};
    		items.push(item);
    	});    	
    	if(prevStepId=="step-2"){
    		window.dataLayer = window.dataLayer || [];
    		dataLayer.push({ 
    			event: "checkout_step2",
    			ecommerce: {
    				items: items    					
    			}
    		});
    	}
    	if(prevStepId=="step-1"){    		
    		window.dataLayer = window.dataLayer || [];
    		dataLayer.push({ 
    			event: "checkout_step1",
    			ecommerce: {
    				items: items    					
    			}
    		});
    	}
    });

    function submitToKlaviyo1(email, fname, lname,consent) {
        $.ajax({
            type: 'POST',
            url: wc_checkout_params.ajax_url, // Use the appropriate URL
            data: {
                action: 'submit_form_klaviyo1', // Create a WordPress action hook for this
                fname: fname,
                lname: lname,
                email: email,
                consent: consent,
            },
            success: function (response) {
                // console.log(response);
                // console.log('Successful!!!');
                // Handle the success response, if needed
            },
            error: function (xhr, status, error) {
                // Handle errors, if needed
            }
        });
    }

    function submitToKlaviyo2(email, fname, lname, phone_number, addr_street, addr_city, addr_zip, addr_state, addr_country) {
        $.ajax({
            type: 'POST',
            url: wc_checkout_params.ajax_url, // Use the appropriate URL
            data: {
                action: 'submit_form_klaviyo2', // Create a WordPress action hook for this
                fname: fname,
                lname: lname,
                email: email,
                phone_number: phone_number,
                addr_street: addr_street,
                addr_city: addr_city,
                addr_zip: addr_zip,
                addr_state: addr_state,
                addr_country: addr_country,
                // Include other fields as needed
            },
            success: function (response) {
                // console.log(response);
                // console.log('Successful!!!');
                // Handle the success response, if needed
            },
            error: function (xhr, status, error) {
                // Handle errors, if needed
            }
        });
    }


    // function toggleActiveLabel() {
    //     var radioButtons = $('.radio-toggle .input-radio');

    //     // Add 'active-label' class to the initially checked radio button's label on page load
    //     var initialCheckedRadio = radioButtons.filter(':checked');
    //     var initialCheckedLabel = initialCheckedRadio.closest('label');
    //     initialCheckedLabel.addClass('active-label');

    //     // Add change event handler to radio buttons
    //     radioButtons.change(function () {
    //         var curval = ($(this).val() === '0') ? true : false;
    //         $('#ship-to-different-address-checkbox').prop('checked', curval);
    //         $('#ship-to-different-address-checkbox').trigger('click');

    //         // Remove the 'active-label' class from all labels
    //         $('.radio-toggle label').removeClass('active-label');

    //         // Add the 'active-label' class to the label associated with the selected radio button
    //         $(this).closest('label').addClass('active-label');
    //     });
    // }

    // // Call the function to initialize the behavior
    // toggleActiveLabel();
    /**
    * Collects billing address data from form fields.
    * @returns {Object} An object containing billing address data.
    */
    function collectBillingAddressData() {
        // Collect billing address data
        const billingData = {
            first_name: $('#billing_first_name').val(),
            last_name: $('#billing_last_name').val(),
            company: $('#billing_company').val(),
            address_1: $('#billing_address_1').val(),
            address_2: $('#billing_address_2').val(),
            city: $('#billing_city').val(),
            state: $('#billing_state').val(),
            postcode: $('#billing_postcode').val(),
            country: $('#billing_country').val(),
            email: $('#billing_email').val(),
            phone: $('#billing_phone').val()
        };

        return billingData;
    }


    /**
   * Collects shipping address data from form fields.
   * @returns {Object} An object containing shipping address data.
   */
    function collectShippingAddressData() {
        // Collect shipping address data
        const shippingData = {
            first_name: $('#shipping_first_name').val(),
            last_name: $('#shipping_last_name').val(),
            company: $('#shipping_company').val(),
            address_1: $('#shipping_address_1').val(),
            address_2: $('#shipping_address_2').val(),
            city: $('#shipping_city').val(),
            state: $('#shipping_state').val(),
            postcode: $('#shipping_postcode').val(),
            country: $('#shipping_country').val()
        };

        return shippingData;
    }

    const selectedProducts = {};
    let selectedProductIDs = [];

    function validateRequiredFields() {
        // Implement your validation logic here
        // You can check if all required fields have values and return true if they are valid, or false if any are missing
        // For example, if you have a required input with an ID "billing_address", you can validate it like this:
        var billingAddress = $('#billing_address').val();
        if (!billingAddress) {
            return false; // The field is empty, return false to indicate validation failure
        }

        // Add more validation logic for other required fields as needed

        return true; // All required fields are filled, return true to indicate validation success
    }

    function updateShippingAttributes() {    	
        $('.dof-cart__products .dof-cart__product__inner').each(function () {        	
            if($(".main-product").length==0) {            	
                var shippingAttribute = $(this).attr('shipping-attribute');                
                if (shippingAttribute && shippingAttribute.startsWith('-')) {
                    var updatedAttribute = shippingAttribute.substring(1); // Remove the minus sign
                    $(this).attr('shipping-attribute', updatedAttribute);
                }
            }
        });
    }

    // $('.cart-remove').on('click', function (e) {
    $('.dof-checkout__cart .cart-remove').on('click', function (e) {
        e.preventDefault();
        const $cartItem = $(this).closest('.dof-cart__product__inner');
        const selectedCountry = $('#billing_country').val();
        const cartItemKey = $(this).data('cart-item-key');
        const productId = $(this).data('cart-remove-product-id');
        const $this = $(this);        
        //console.log('====================================');
        //console.log('item-remove');
        //console.log('====================================');
        // Check if the element does not have the hide-on-mobile class
        /*if (!$cartItem.hasClass('hide-on-mobile')) {
            // Find the next block and remove the hide-on-mobile class from it
            $cartItem.next().removeClass('hide-on-mobile').show();
            $cartItem.show();
        }*/


        // Store removed cart item data in case of Undo
        // const removedCartItem = {
        //     cartItemKey: cartItemKey,
        //     removedData: removedData
        // };

        $.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.ajax_url,
            data: {
                action: 'remove_cart_item',
                cart_item_key: cartItemKey,
                product_id: productId,
            },
            success: function (response) {
                // Update the current cart item content with the response HTML            	
                $cartItem.replaceWith(response);
                updateShippingAttributes();
                const visibleMainProducts = $('.dof-cart__product__inner').not(".cart-enable_warranty").length;
                // console.log('====================================');
                // console.log(visibleMainProducts);
                // console.log('====================================');
                // const cartEnableWarranty = $('.dof-cart__product__inner').hasClass('cart-enable_warranty');           
                if (visibleMainProducts <= 1) {
                    $('#show-products').hide();
                }
                // // Check if the cart is empty
                if (visibleMainProducts === 0) {
                    $('.checkout-promo').css('display', 'none');
                    // The cart is empty, so you can choose whether to reload the page or perform other actions
                    // window.location.reload();
                    localStorage.clear();
                    location.reload();
                } else {
                    // The cart is not empty, perform other actions
                    recalculateShippingCost();
                    // $('.addToCartCheckbox').prop('checked', false);
                    // console.log('====================================');
                    // console.log( $('#checkbox-option-' + productId).find('.addToCartCheckbox').prop('checked', false));
                    // console.log('====================================');
                    localStorage.setItem('addToCartCheckboxState_' + productId, 'unchecked');
                    //localStorage.removeItem('addToCartCheckboxState_' + productId);
                    // $(this).load(location.href + ' .dof-cart__product__inner');

                    // Reload the current cart item content using AJAX
                    $this.load(location.href + ' .dof-cart__product__inner', function () { });
                    calculateAndUpdatePrices();
                    const promoCodeInput = $('.checkout-promo #promo_code');
                    if (promoCodeInput.val()) {
                        promoCodeInput.val('');
                    }
                    const promoErrorElement = $('#promo_error');
                    if (promoErrorElement.length) {
                        promoErrorElement.html(''); // Clear the content of the element
                    }
                }                
            },
        });
    });

    function isInEurope(countryCode) {
        // List of European country codes
        const europeanCountryCodes = ["AT", "BE", "BG", "HR", "CY", "CZ", "DE", "DK", "EE", "FI", "FR", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"];
        return europeanCountryCodes.includes(countryCode);
      }


    // Function to recalculate shipping cost
    function recalculateShippingCost() {    	
        let selectedCountry = $('select#billing_country').val();
        let selectedAttributes = [];
        var selectedProductsIDs = []; // Initialize an array to store selected product attributes    
        let countryEurope = isInEurope(selectedCountry);

        // Iterate through all divs with the shipping-attribute attribute
        $('.dof-cart__products [shipping-attribute]').each(function () {
            var shippingAttribute = $(this).attr('shipping-attribute');
            if (shippingAttribute) {
                selectedAttributes.push(shippingAttribute);
            }
        });

        $('.dof-cart__products [data-product-id]').each(function () {
            var productId = $(this).attr('data-product-id');
            selectedProductsIDs.push(productId);
        });
        // Make an AJAX request to calculate the shipping price
        $.ajax({
            type: 'POST',
            url: wc_checkout_params.ajax_url, // WordPress AJAX URL (automatically defined)
            data: {
                action: 'calculate_shipping_price',
                country_europe: countryEurope,
                selected_country: selectedCountry,
                selected_attributes: selectedAttributes.join(','),
                selected_products_ids: selectedProductsIDs.join(',')
            },
            success: function (response) {
                // Parse the JSON response
                var data = JSON.parse(response);
                var shippingCost = data.shipping_cost;
                var tax = data.tax;
                var total = data.total;
                var subtotal = data.subtotal; // Added to capture subtotal           
                // Update the displayed shipping cost and total on the frontend
                $('.delivery-price').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>' + shippingCost + '</bdi></span>');                
                if(data.shipping_cost_real==-2){                	
                	$('.delivery-price').html('Place order');
                    $('.dof-tax').html('Place order');
                	$('.proceed-payment-btn').html('Place order');                	                	
                }else{
                	$('.proceed-payment-btn').html('Proceed to Payment');
                }
              	$('.dof-tax').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>' + tax + '</bdi></span>');
                $('.subtotal').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>'+subtotal+'</bdi></span>');               
                $('.total').html('<span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">'+data.currency_symbol+'</span>'+total+'</bdi></span>');
            }
        });
    }

    // Make sure the element exists in the DOM
    const billingCountrySelect = $('.dof-checkout #billing_country');

    if (billingCountrySelect.length > 0) {
        billingCountrySelect.prop('selectedIndex', 0); // Set the default option as selected
    }


    function calculateAmountToDeductPartner() {
        let totalDeductAmount = 0;

        $('.dof-cart__product__inner').not('.main-product, .our-option').each(function () {
            const priceElement = $(this).find('.dof-cart__product__price .woocommerce-Price-amount');
            if (priceElement.length) {
                const priceText = priceElement.text();
                const price = parseFloat(priceText.replace(currency, '').trim());
                totalDeductAmount += price;
            }
        });

        return totalDeductAmount;
    }


    function calculateAmountToDeduct() {
        let totalDeductAmount = 0;

        $('.dof-cart__product__inner').each(function () {
            var $productItem = $(this).closest('.dof-cart__product__inner');
            if (!$productItem.hasClass('cart-enable_warranty')) {
                // Check if the product has the class cart-enable_warranty
                if ($(this).hasClass('main-product') || $(this).hasClass('our-option')) {
                    // const priceElement = $(this).find('.dof-cart__product__price .woocommerce-Price-amount');
                    const priceElement = $(this).find('.dof-cart__product__price');
                    const shipping = $(this).attr("shipping-product-crm");                    
                    if (priceElement.length) {
                        // const priceText = priceElement.text();
                        // const price = parseFloat(priceText.replace('$', '').trim());
                        const priceText = priceElement.text().trim().replace(/\s/g, '');
                        const price = parseFloat(priceText.replace(currency, '').replace(' ', ''));
                        totalDeductAmount += (price - shipping);
                    }
                }
            }
        });

        return totalDeductAmount;
    }

    $("#apply_promo_button").on('click', function (event) {
        event.preventDefault();
        const dofCountry = $('.tab-pane').find('select#billing_country').val();
        if (dofCountry == '') {
            $("#promo_error").css("color", "red").text("To apply the promo code, you need to select a country");
        } else {
            const code = $("#promo_code").val().toLowerCase();
            // const amountToDeductPartner = calculateAmountToDeductPartner();
            const amountToDeduct = calculateAmountToDeduct();
            // const promoCode = $('.total .woocommerce-Price-amount span').data("promo-code");
            // const discountPercentage = $('.total .woocommerce-Price-amount span').data("discounte");
            // console.log('====================================');
            // console.log(amountToDeduct);
            // console.log('====================================');            
            var model=$("div[data-product-type='simulator']").attr('shipping-attribute');
            $.ajax({
                url: '../proxy.php',
                type: 'GET',
                data: {
                    code: code,
                    amount: amountToDeduct,
                    model: model
                },
                success: function (data) {
                    let percentageDiscount = ((amountToDeduct - data) / amountToDeduct) * 100;
                    let discountSumResult = amountToDeduct - data;
                    let roundedPercentageDiscount = Math.round(percentageDiscount);
                    let dofCheckoutCart = $('.dof-checkout__cart');
                    let cartProductInfo = $('.cart-product__info');

                    const codeAttr = data != amountToDeduct ? code : '';
                    let shippingAmountText = dofCheckoutCart.find('.delivery-price .woocommerce-Price-amount').text();
                    let shippingAmount = Math.round(parseFloat(shippingAmountText.replace(currency, '').trim()));

                    let subTotalPrice = 0;
                    let subTotalWarrantyPrice = 0;

                    $('.product-item').each(function () {

                        var $productItem = $(this).closest('.product-item');
                        const productID = $(this).data('product-id');
                        const quantity = $(this).data('quantity');

                        const hasOurOption = $(this).hasClass('our-option');
                        const mainProduct = $(this).hasClass('main-product');
                        if (!$productItem.hasClass('cart-enable_warranty')) {
                            if (mainProduct || hasOurOption) {
                                selectedProducts[productID] = quantity;
                                selectedProductIDs.push(productID);
                            }
                        }
                    });
                    if (data < amountToDeduct) {
                        let discountedAmount = Math.round(parseFloat(data.toFixed(2)));
                        // Convert to a number                            
                        let taxText = dofCheckoutCart.find('.dof-tax .woocommerce-Price-amount').text();
                        let taxAmount = Math.round(parseFloat(taxText.replace(currency, '').trim())); // Convert to a number
                        let subTotal = dofCheckoutCart.find('.total .woocommerce-Price-amount').text();                                                
                        subTotal = parseFloat(subTotal.replace(currency, '').trim().replace(',', ''));
                        
                        subTotal = subTotal-discountSumResult-taxAmount-shippingAmount;
                        
                        // Store the discount amount in session or any other method you prefer
                        $.ajax({
                            url: dofreality_ajax.url, // Replace with your WordPress AJAX endpoint
                            type: 'POST',
                            data: {
                                action: 'store_dof_discount',
                                discount_amount: data, // Pass the discount amount to WordPress backend
                                discount_percentage: roundedPercentageDiscount,
                                coupon_code: code,
                                selected_our_products_ids: selectedProductIDs,
                                discountSumResult:discountSumResult
                            },
                            success: function (response) {
                                console.log('Discount amount stored:', data);
                            },
                            error: function () {
                                console.error('Failed to store discount amount');
                            }
                        });



                        // Check if both discountedAmount and shippingAmount are valid numbers
                        if (!isNaN(discountedAmount)) {
                        	
                            var subTotalPriceElement = dofCheckoutCart.find('.subtotal .woocommerce-Price-amount');
                            if (subTotalPriceElement.length > 0) {
                                subTotalPriceElement.html('<bdi><span class="woocommerce-Price-currencySymbol">'+currency+'</span>' + subTotal + '</bdi>');
                            }
                            if(isNaN(shippingAmount)){
                            	shippingAmount=0;                            	
                            }
                            if(isNaN(taxAmount)){
                            	taxAmount=0;                            	
                            }
                            // Update the total amount with the formatted result                            
                            let totalAmount = subTotal + parseFloat(shippingAmount) + parseFloat(taxAmount); // Add them together
                            let totalPriceElement = dofCheckoutCart.find('.total .woocommerce-Price-amount');
                            if (totalPriceElement.length > 0) {
                                totalPriceElement.html('<bdi><span class="woocommerce-Price-currencySymbol" data-promo-code="' + codeAttr + '" data-discounte="' + roundedPercentageDiscount + '">'+currency+'</span>' + totalAmount + '</bdi>');
                            }


                            $("#promo_error").css("color", "green").text("Code Applied");

                            $('.cart-product__info .discount-code').css('display', 'flex');;
                            cartProductInfo.find('.discount-code .item-name span').text(parseFloat(percentageDiscount.toFixed(1)));
                            cartProductInfo.find('.discount-code .discount-code-sum').html('-'+ currency + parseFloat(discountSumResult.toFixed(1)));

                            $("#apply_promo_button").hide();
                        } else {
                            // Handle cases where one or both values are not valid numbers
                            console.log('Invalid numbers: discountedAmount =' + discountedAmount + 'shippingAmount =' + shippingAmount);
                            // Display an error message or take appropriate action
                        }

                    } else {
                        $("#promo_error").css("color", "red").text("Sorry, the promo code is not valid");
                        $("#promo_code").val("");
                        localStorage.removeItem('promo_code');
                    }
                },
                error: function () {
                    $("#promo_error").css("color", "red").text("An error occurred while checking the promo code.");
                }
            });
        }
    });

    // Function to hide the Place Order button based on the selected payment method
    function togglePlaceOrderButton() {
        var selectedPaymentMethod = $('input[name="payment_method"]:checked').val();
        var account_code = $('input[name="payment_method"]:checked').data('account-code');
        if (selectedPaymentMethod === 'stripe') {
            $('.form-row.place-order #place_order').hide();
            $('.form-row.place-order .dof-stripe-button').show();
        } else {
        	if(selectedPaymentMethod){
        		$('.form-row.place-order #place_order').show();
        		$('.form-row.place-order .dof-stripe-button').hide();
        	}
        }
    }
    // Initial call to toggle button visibility on page load
    togglePlaceOrderButton();

    // Event listener for payment method change
    $('form.checkout').on('change', 'input[name="payment_method"]', function () {
        togglePlaceOrderButton();
        customizePaymentMethodRadio();
    });

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    $('#step-2').on('click', '.steps-buttons .proceed-payment-btn', function (e) {
        e.preventDefault();      
    	if (validateStep2Fields()) {
        	total = $('.dof-checkout__cart').find('.total .woocommerce-Price-amount bdi').text();
        	if(total==0){
        		$.magnificPopup.open({
                    items: {
                        src: '#info-checkout-popup'
                    }
                });  
        		return;
        	}
            // Collect WooCommerce checkout data for billing
            var billingCountryField = $('#billing_country');
            var selectedCountryOption = billingCountryField.find('option:selected');
            var billingStateField = $('#billing_state');
            var selectedStateOption = billingStateField.find('option:selected');
            // Collect checkbox value
            var newsletterCheckboxValue = $('#newsletter_checkbox').prop('checked') ? 'Yes' : 'No';
            var agreeTermsCheckboxValue = $('#agree_terms_field').prop('checked') ? 'Yes' : 'No';
            // Get the value from the billing state input
            var billingStateInput = $('#hidden_billing_state').val();
            var dofAccountPayment = $('#step-2').attr('dof-payment-choose');
            // Collect WooCommerce cart product data
            var products = [];
            $('.dof-cart__product__inner').each(function () {
                var productInfo = $(this).find('.dof-cart__product__info');
                var hasOurOption = $(this).hasClass('our-option'); // Check if it has our-option class
                var isMainProduct = $(this).hasClass('main-product'); // Check if it's a main-product
                var shippingAttribute = $(this).attr('shipping-attribute');
                var dataProductType = $(this).attr('data-product-type');
                var shippingProduct = $(this).attr('shipping-product');
                var usage = $(this).attr('data-usage');
                var productName = productInfo.find('h6').text();
                var productPrice = $(this).find('.dof-cart__product__price').text().trim();
                var href=$(this).find('a').attr("href");
                $(this).find('a').attr("data-url",href);
                $(this).find('a').attr("href","javascript:void(0)");
                $(this).find('a').attr("target","");
                $(this).find('a').addClass("no-link");
                $(".dof-cart__remove").hide();
                // Remove dollar sign and spaces from the price
                productPrice = productPrice.replace(currency, '').replace(/\s/g, '');

                // Determine the option based on the presence of .our-option, excluding main-product
                var optionText = hasOurOption ? 'Our Option' : 'Partner Option';
                // Add product data to the array, excluding option if it's a main-product
                var productData = {
                    name: productName,
                    model: shippingAttribute,
                    product_type: dataProductType,
                    price: productPrice,
                    shipping: shippingProduct,
                    usage: usage
                };

                // Include option only if it's not a main-product
                if (!isMainProduct) {
                    productData.option = optionText;
                }

                products.push(productData);
            });

            // Collect additional cart information
            var discountCode = $('#promo_code').val().trim(); // Get entered discount code
            var discountCodeSum = $('.discount-code-sum').text();
            var subtotal = $('.subtotal .woocommerce-Price-amount').text().trim();
            var deliveryPrice = $('.delivery-price').text().trim();
            var dofTax = $('.dof-tax').text().trim();
            if (discountCode) {
                var total = $('.total .woocommerce-Price-amount').text().trim();
            } else {
                var total = $('.total .woocommerce-Price-amount .amount').text().trim();
            }

            // Remove dollar signs from various price-related fields
            discountCodeSum = discountCodeSum.replace(currency, '').replace(/\s/g, '');
            subtotal = subtotal.replace(currency, '').replace(/\s/g, '');
            deliveryPrice = deliveryPrice.replace(currency, '').replace(/\s/g, '');
            dofTax = dofTax.replace(currency, '').replace(/\s/g, '');
            total = total.replace(currency, '').replace(/\s/g, '');

            // Create the checkout data object
            var checkoutData = {
                billing_first_name: $('#billing_first_name').val(),
                billing_last_name: $('#billing_last_name').val(),
                billing_country_name: selectedCountryOption.text(),
                billing_country_code: selectedCountryOption.val(),
                billing_address_1: $('#billing_address_1').val(),
                billing_address_2: $('#billing_address_2').val(),
                billing_city: $('#billing_city').val(),
                billing_state_name: billingStateInput ? billingStateInput : selectedStateOption.text(),
                billing_state_code: selectedStateOption.val(),
                billing_postcode: $('#billing_postcode').val(),
                billing_phone: $('#billing_phone').val(),
                billing_email: $('#billing_email').val(),
                company: $('#billing_company').val(),
                comments: $('#order_comments').val(),
                products: products,
                subscribe: newsletterCheckboxValue,
                agree_terms: agreeTermsCheckboxValue,
                discount_code: discountCode,
                discount_code_sum: discountCodeSum,
                subtotal: subtotal,
                delivery_price: deliveryPrice,
                dof_tax: dofTax,
                total: total,
                account_code: dofAccountPayment,
                uuid: uuidv4(),
                utm_campaign:localStorage.getItem("utm_campaign"),
                utm_source:localStorage.getItem("source"),
                rpc: localStorage.getItem("rpc"),
                utm_medium: localStorage.getItem("utm_medium"),
                utm_content:localStorage.getItem("utm_content")                               
            };
            //console.log(checkoutData);
            // Send data using AJAX
            $.ajax({
                type: 'POST',
                url: dofreality_ajax.url, // Use the correct AJAX URL provided by WordPress
                data: {
                    action: 'process_checkout',
                    checkout_data: checkoutData
                },
                success: function (response) {
                    // Handle the response from the PHP file
                   // console.log(response); 
                	if($('.proceed-payment-btn').html()=='Place order'){
                		$.magnificPopup.open({
                			items: {
                				src: '#delivery-zero' ,
                				 closeOnBgClick: false
                			},
                			callbacks: {
                				beforeOpen: function () {
                					hidePreloader();
                					this.st.mainClass = 'mfp-zoom-in add-product-popup__wrap'; // Add your desired animation class
                				},
                				beforeClose: function () {
                                     $.ajax({
                                            type: 'POST',
                                            url: ajax_order_params.ajax_url,
                                            data: {
                                                action: 'clear_cart_ajax' // Create a custom AJAX action to clear the cart
                                            },
                                            success: function (cartResponse) {
                                                // Clear the checkout form
                                                $('#checkout-form')[0].reset();
                                                $('.tab-pane-info').load(location.href + ' .tab-pane-info');
                                                $(".dof-checkout__cart").remove();
                                                //localStorage.clear();
                                                window.location.href = '/';
                                            },
                                            error: function (cartError) {
                                                // Handle errors related to clearing the cart
                                                console.error(cartError);                                                
                                            }
                                    });
                					this.st.mainClass = 'mfp-zoom-out'; // Add your desired animation class
                				}
                			}        	        	
                		});
                	}
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error('AJAX error:', textStatus, errorThrown);
                    // console.log(jqXHR.responseText);
                }
            });
        }
    });

    function toggleProducts() {
        // Check window width
        if ($(window).width() <= 992) {
            const $productInners = $('.dof-cart__products .dof-cart__product__inner');
            const $checkoutCart = $('.dof-checkout__cart');
            const $cartInfo = $('.cart-product__info-text.mobile-items');

            // Check if dof-checkout__cart has step-2 class
            const isStep2 = $checkoutCart.hasClass('step-2');

            if (isStep2) {
                // Close all elements
                $productInners.hide();
                $('.checkout-promo.step-2').hide();
                $('#show-products').removeClass('rotated');
                $('.dof-cart__products').addClass('close-products');
                $cartInfo.css('display', 'flex');
            } else {
                // Toggle visibility of product inners when #show-products is clicked
                $('#show-products').on('click', function () {
                    $productInners.slideToggle();
                    $('.checkout-promo.step-2').slideToggle({
                        start: function () {
                            $(this).css('display', 'flex');
                        }
                    });
                    $('#show-products').toggleClass('rotated');
                    $('.dof-cart__products').toggleClass('close-products');

                    // Count the number of visible product inners
                    const visibleProductCount = $productInners.filter(':visible').length;

                    // Toggle visibility of cart info
                    $cartInfo.slideToggle({
                        start: function () {
                            $(this).css('display', 'flex');
                        }
                    });

                    $cartInfo.find('.item-name').text(`Your Cart (${visibleProductCount} item${visibleProductCount !== 1 ? 's' : ''})`);
                });

                // Initially show product inners
                $productInners.show();

                // Count the number of visible product inners
                const visibleProductCount = $productInners.filter(':visible').length;

                // Update cart info
                $cartInfo.find('.item-name').text(`Your Cart (${visibleProductCount} item${visibleProductCount !== 1 ? 's' : ''})`);


            }
        }
    }

    // Initial call to toggleProducts
    toggleProducts();

    function calculateAndUpdatePrices() {
        var priceSum = 0;
    
        $('.dof-cart .dof-cart__products .product-item:not(.cart-enable_warranty)').filter('.our-option, .main-product').each(function () {
            var $productItem = $(this);
            // Extract the price text and remove any spaces before converting to float
            var priceText = $productItem.find('.dof-cart__product__price').text().replace(/\s/g, '');
            var price = parseFloat(priceText.replace(currency, '').trim());
            var shipping = $(this).attr('shipping-product-crm');
            priceSum += price-shipping;
        });

        // Calculate the percentage amount
        var warrantyPercentage = parseFloat($('.dof-cart__products').find('.cart-enable_warranty').data('enable-warranty-percentage'));
        var updatedPrice = (priceSum * warrantyPercentage) / 100;
        // Update the displayed price with the calculated warranty price
        $('.dof-cart__products').find('.cart-enable_warranty .dof-cart__product__price').text(currency + Math.round(updatedPrice));
    }    
    calculateAndUpdatePrices();
    
    if(localStorage.getItem("checkoutCurrentStep")){
		if(localStorage.getItem("billing_first_name")){
			$("#billing_first_name").attr('value',localStorage.getItem("billing_first_name"));
		}
		if(localStorage.getItem("billing_last_name")){
			$("#billing_last_name").attr('value',localStorage.getItem("billing_last_name"));
		}
		if(localStorage.getItem("billing_email")){
			$("#billing_email").attr('value',localStorage.getItem("billing_email"));
		}
		if(localStorage.getItem("billing_country")){			
			$('select#billing_country').val(localStorage.getItem("billing_country"));			
			setTimeout(function(){$('select#billing_country').trigger("change");},200);
		}
		if(localStorage.getItem("billing_address_1")){
			$("#billing_address_1").attr('value',localStorage.getItem('billing_address_1'));
		}
		if(localStorage.getItem("billing_address_2")){
         $("#billing_address_2").attr('value',localStorage.getItem('billing_address_2'));
		}
		if(localStorage.getItem("billing_city")){
			$("#billing_city").attr("value",localStorage.getItem('billing_city'));
		}
		if(localStorage.getItem("billing_postcode")){
			$("#billing_postcode").attr("value",localStorage.getItem('billing_postcode'));
		}		
		if(localStorage.getItem("billing_company")){
			$("#billing_company").attr("value",localStorage.getItem('billing_company'));
		}
		if(localStorage.getItem("newsletter_checkbox")){
			if(localStorage.getItem("newsletter_checkbox")==1){
				$("#newsletter_checkbox").attr("checked","checked");
			}
		}
		if(localStorage.getItem("agree_terms_field")){
			if(localStorage.getItem("agree_terms_field")==1){
				$("#agree_terms_field").attr("checked","checked");
				$('#agree_terms_field').trigger("change");
			}
		}			
		 if($("#promo_code") && localStorage.getItem('promo_code')!=null){
		    	$(".checkout-promo #promo_code").attr("value",localStorage.getItem('promo_code'));
		    	$(".checkout-promo #promo_code").attr("data-gtm-form-interact-field-id",0);
		    	$(".checkout-promo #promo_code").focus();
		    	$("#apply_promo_button").trigger("click");
		    }
		if(localStorage.getItem("checkoutCurrentStep")>0){
			setTimeout(function(){								
				is_step_storage=1;
				$("#step-"+localStorage.getItem("checkoutCurrentStep")+" .next-btn").trigger("click");					
			},300);
		}
	}	

});